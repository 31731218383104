import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from '../modules/helpers';
import { ActionTypes } from '../constants/index';

export const appState = {
  categories: [],
  loading: false,
};

export default {
  category: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_ALL_CATEGORIES_REQUEST]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_ALL_CATEGORIES_SUCCESS]: (draft, { payload: { result } }) => {
        draft.categories = result;
        draft.loading = false;
      },
      [ActionTypes.GET_ALL_CATEGORIES_FAILURE]: draft => {
        draft.loading = false;
      },
    },
    appState,
  ),
};
