/**
 * @module Actions/Card
 * @desc Card Actions
 */

import { createActions } from 'redux-actions';
import { ActionTypes } from '../constants/index';

export const {
  getCardsRequest,
  getCardsSuccess,
  getCardsFailure,
  getCardRequest,
  getCardSuccess,
  getCardFailure,
  createCardRequest,
  createCardSuccess,
  createCardFailure,
  editCardsRequest,
  editCardsSuccess,
  editCardsFailure,
  deleteCardAndSendNotificationRequest,
  approveCardsRequest,
  approveCardsSuccess,
  approveCardsFailure,
} = createActions({
  [ActionTypes.GET_CARDS_REQUEST]: data => data,
  [ActionTypes.GET_CARDS_SUCCESS]: data => data,
  [ActionTypes.GET_CARDS_FAILURE]: () => ({}),
  [ActionTypes.GET_CARD_REQUEST]: id => ({ id }),
  [ActionTypes.GET_CARD_SUCCESS]: data => data,
  [ActionTypes.GET_CARD_FAILURE]: () => ({}),
  [ActionTypes.CREATE_CARD_REQUEST]: data => data,
  [ActionTypes.CREATE_CARD_SUCCESS]: () => ({}),
  [ActionTypes.CREATE_CARD_FAILURE]: () => ({}),
  [ActionTypes.EDIT_CARDS_REQUEST]: data => data,
  [ActionTypes.EDIT_CARDS_SUCCESS]: data => data,
  [ActionTypes.EDIT_CARDS_FAILURE]: () => ({}),
  [ActionTypes.APPROVE_CARDS_REQUEST]: data => data,
  [ActionTypes.APPROVE_CARDS_SUCCESS]: data => data,
  [ActionTypes.APPROVE_CARDS_FAILURE]: () => ({}),
  [ActionTypes.DELETE_CARD_AND_SEND_NOTIFICATION_REQUEST]: data => data,
  [ActionTypes.DELETE_CARD_AND_SEND_NOTIFICATION_SUCCESS]: data => data,
  [ActionTypes.DELETE_CARD_AND_SEND_NOTIFICATION_FAILURE]: () => ({}),
});
