import React, { Component } from 'react';
import Moment from 'moment';
import styled from 'styled-components';
import { Button, Form, FormControl, Row, Col, Container } from 'react-bootstrap';
import Icon from '../../components/Icon';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Colors } from '../../modules/theme';

const InputPassword = styled.div`
  position: relative;
`;

export default class ChangePasswordForm extends Component {
  state = {
    hidePassword: true,
  };

  render() {
    const { loading, initialValues, onSubmit } = this.props;
    const { hidePassword } = this.state;

    return (
      <Container className="pb-5">
        <Row>
          <Col className="pt-5 row-spc-between" xs={12} sm={12} md={12} ls={12}>
            <h1 className="h1-list">Change Password</h1>
          </Col>

          <Col className="pt-5 justify-content-start" xs={12} sm={12} md={12} ls={12}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .required('Required')
                  .min(8, 'The password must have more than eight characters'),
                confirmPassword: Yup.string().test(
                  'match',
                  'Passwords must match',
                  function (confirmPassword) {
                    return confirmPassword === this.parent.password;
                  },
                ),
              })}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isValid,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Row className="g-2 p-4">
                      <Col md>
                        <Form.Group className="mb-5" controlId="password">
                          <Form.Label as="h4">Password*</Form.Label>

                          <InputPassword>
                            <Form.Control
                              type={hidePassword ? 'password' : 'text'}
                              name="password"
                              placeholder="Password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <Icon
                              fontSize={22}
                              name={hidePassword ? 'eye' : 'eye-slash'}
                              color={Colors.blue}
                              customStyle={{ position: 'absolute', right: 10, top: 10 }}
                              onClick={() =>
                                this.setState({
                                  hidePassword: !hidePassword,
                                })
                              }
                            />
                          </InputPassword>
                          <Form.Control.Feedback type="invalid">
                            {errors.password && touched.password && errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md>
                        <Form.Group className="mb-5" controlId="confirmpassword">
                          <Form.Label as="h4">Confirm password*</Form.Label>

                          <InputPassword>
                            <Form.Control
                              type={hidePassword ? 'password' : 'text'}
                              name="confirmPassword"
                              placeholder="Confirm password"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <Icon
                              fontSize={22}
                              name={hidePassword ? 'eye' : 'eye-slash'}
                              color={Colors.blue}
                              customStyle={{ position: 'absolute', right: 10, top: 10 }}
                              onClick={() =>
                                this.setState({
                                  hidePassword: !hidePassword,
                                })
                              }
                            />
                          </InputPassword>
                          <Form.Control.Feedback type="invalid">
                            {errors.confirmPassword &&
                              touched.confirmPassword &&
                              errors.confirmPassword}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Button variant="primary mb-5" size="lg" type="submit" disabled={loading}>
                        {loading ? 'Loading…' : 'Submit'}
                      </Button>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    );
  }
}
