import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from '../modules/helpers';
import { ActionTypes } from '../constants/index';

const ITEM_DEFAULT = {
  title: '',
  messageType: '',
  message: '',
};

export const appState = {
  list: [],
  pages: 0,
  currentPage: 0,
  loading: false,
  item: ITEM_DEFAULT,
  loadingSave: false,
  showForm: false,
  isEditing: false,
  currentTab: 'Sent',
};

export default {
  notification: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_NOTIFICATIONS_REQUEST]: (draft, { payload: { type } }) => {
        draft.loading = true;
        draft.currentTab = type;
      },
      [ActionTypes.GET_NOTIFICATIONS_SUCCESS]: (
        draft,
        {
          payload: {
            result,
            metadata: { totalPages, currentPage },
          },
        },
      ) => {
        draft.list = result;
        draft.pages = totalPages;
        draft.currentPage = currentPage;
        draft.loading = false;
      },
      [ActionTypes.GET_NOTIFICATIONS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_NOTIFICATION_REQUEST]: draft => {
        draft.loadingSave = true;
      },
      [ActionTypes.CREATE_NOTIFICATION_SUCCESS]: draft => {
        draft.loadingSave = false;
      },
      [ActionTypes.CREATE_NOTIFICATION_FAILURE]: draft => {
        draft.loadingSave = false;
      },
      [ActionTypes.EDIT_NOTIFICATION_REQUEST]: draft => {
        draft.loadingSave = true;
      },
      [ActionTypes.EDIT_NOTIFICATION_SUCCESS]: draft => {
        draft.loadingSave = false;
      },
      [ActionTypes.EDIT_NOTIFICATION_FAILURE]: draft => {
        draft.loadingSave = false;
      },
      [ActionTypes.DELETE_NOTIFICATION_REQUEST]: draft => {
        draft.loadingSave = true;
      },
      [ActionTypes.DELETE_NOTIFICATION_SUCCESS]: draft => {
        draft.loadingSave = false;
      },
      [ActionTypes.DELETE_NOTIFICATION_FAILURE]: draft => {
        draft.loadingSave = false;
      },
      [ActionTypes.SWITCH_FORM_VISIBLE]: (draft, { payload: { showForm, isEditing, item } }) => {
        draft.showForm = showForm;
        draft.isEditing = isEditing;
        draft.item = item || ITEM_DEFAULT;
      },
    },
    appState,
  ),
};
