/**
 * @module Actions/Category
 * @desc Category Actions
 */

import { createActions } from 'redux-actions';
import { ActionTypes } from '../constants/index';

export const {
  getAllCategoriesRequest,
  getAllCategoriesSuccess,
  getAllCategoriesFailure,
} = createActions({
  [ActionTypes.GET_ALL_CATEGORIES_REQUEST]: data => data,
  [ActionTypes.GET_ALL_CATEGORIES_SUCCESS]: data => data,
  [ActionTypes.GET_ALL_CATEGORIES_FAILURE]: () => ({}),
});
