import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import Icon from '../../components/Icon';
import { Colors } from '../../modules/theme';
import { replace } from '../../modules/history';
import Logo from '../../../assets/media/images/Logo.png';
import styled from 'styled-components';

const Content = styled.div``;

export default class RecoverPassView extends Component {
  static propTypes = {
    recoverPassword: PropTypes.func.isRequired,
  };

  submit = (values) => {
    const { recoverPassword } = this.props;
    const { email } = values;
    recoverPassword(email);
  };

  render() {
    const { loading } = this.props;

    return (
      <Container className="h-100 justify-content-center align-items-center pt-5">
        <Row>
          <Col
            xs={12}
            sm={{ span: 8, offset: 2 }}
            md={{ span: 4, offset: 4 }}
            ls={{ span: 4, offset: 4 }}
          >
            <Content className="pb-5 text-center">
              <img src={Logo} alt="YieldBucks logo" width="260px" />
            </Content>

            <div className="pb-5 pt-5 text-center">
              <h1>Recover password</h1>
              <p style={{ color: '#7B7B7B' }}>
                Enter your email associated with your account and we’ll send you instructions to
                reset your password.
              </p>
            </div>

            <Formik
              enableReinitialize
              initialValues={{ email: '' }}
              onSubmit={this.submit}
              validationSchema={Yup.object().shape({
                email: Yup.string().required('Required').email('Invalid Format'),
              })}
            >
              {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-5" controlId="formBasicEmail">
                    <Form.Label as="h4">Email address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email && touched.email && errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="button-group" className="text-center">
                    <Button
                      variant="primary mb-5 mt-5"
                      size="lg"
                      block
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? 'Loading…' : 'Recover Password'}
                    </Button>
                    <Button
                      className="custom-btn-link"
                      variant="link"
                      onClick={() => replace('/login')}
                    >
                      Back to Login
                    </Button>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    );
  }
}
