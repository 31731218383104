import React, { Component } from 'react';
import { connect } from 'react-redux';
import CardListView from './CardListView';
import {
  getCardsRequest,
  createCardRequest,
  editCardsRequest,
  deleteCardAndSendNotificationRequest,
  approveCardsRequest,
  showAlert,
} from '../../actions/index';

const elementsPerPage = 10;

class CardContainer extends Component {
  state = {
    currentPage: 1,
    orderByDate: 'desc',
    search: '',
    statusFilter: '',
    currentId: null,
    item: { rewards: [] },
    showEditStatusAlert: false,
    showDeleteCardTypeAlert: false,
    showEdit: false,
    isEditing: false,
    type: '',
    deleteCardMessage: '',
    showUploadCsv: false,
  };

  componentDidMount() {
    const { getCards } = this.props;
    getCards();
  }

  changePage = currentPage => {
    this.setState({ currentPage }, () => {
      const { getCards } = this.props;
      const { search, orderByDate } = this.state;

      getCards({
        currentPage,
        elementsPerPage,
        search,
        orderByDate,
      });
    });
  };

  onFilter = statusFilter => {
    this.setState({ statusFilter }, () => {
      const { getCards } = this.props;
      const { search, orderByDate } = this.state;

      getCards({ elementsPerPage, search, statusFilter, orderByDate });
    });
  };

  onOrderByDate = orderByDate => {
    this.setState({ orderByDate }, () => {
      const { getCards } = this.props;
      const { search, statusFilter } = this.state;

      getCards({ elementsPerPage, search, statusFilter, orderByDate });
    });
  };

  onSearch = search => {
    this.setState({ search }, () => {
      const { getCards } = this.props;
      const { currentPage, orderByDate, statusFilter } = this.state;

      getCards({ currentPage, elementsPerPage, search, statusFilter, orderByDate });
    });
  };

  onEditStatus = () => {
    const { editCard, loadingSave } = this.props;
    const { currentId, item } = this.state;

    if (!loadingSave) {
      this.setState({
        currentId: null,
        item: { rewards: [] },
        showEditStatusAlert: false,
        showEdit: false,
        type: '',
      });
    }

    editCard({ id: currentId, params: item });
  };

  approveCardType = () => {
    const { approveCard, loadingSave } = this.props;
    const { currentId, item } = this.state;
    if (!loadingSave) {
      this.setState({
        currentId: null,
        item: { rewards: [] },
        showEditStatusAlert: false,
        showEdit: false,
        type: '',
      });
    }

    approveCard({ id: currentId, params: item });
  };

  switchModal = (currentId, item, showEditStatusAlert, showEdit, isEditing = false, type = '') => {
    this.setState({ currentId, item, showEditStatusAlert, showEdit, isEditing, type });
  };

  switchUploadCsvModal = showUploadCsv => {
    this.setState({ showUploadCsv });
  };

  deleteCardModal = ({ id, showDeleteCardTypeAlert }) => {
    this.setState({ currentId: id, showDeleteCardTypeAlert });
  };

  changeDeleteCardMessage = evt => {
    this.setState({ deleteCardMessage: evt.target.value });
  };

  showUploadAlertFunc = () => {
    const { showUploadAlert } = this.props;

    showUploadAlert();
  };

  deleteCardAndSendNotificationFunc = () => {
    const { currentId, deleteCardMessage } = this.state;
    const { deleteCardAndSendNotification } = this.props;
    deleteCardAndSendNotification({ currentId, deleteCardMessage });
    this.setState({ currentId: null, deleteCardMessage: '', showDeleteCardTypeAlert: false });
  };

  onSubmit = values => {
    const { createCard, editCard, loadingSave } = this.props;
    const { currentId, isEditing } = this.state;

    if (isEditing) {
      editCard({ id: currentId, params: values });
    } else {
      createCard(values);
    }

    if (!loadingSave) {
      this.setState({
        currentId: null,
        item: { rewards: [] },
        showEditStatusAlert: false,
        showEdit: false,
      });
    }
  };

  render() {
    const { cards, pages, currentPage, loading, loadingSave, getCards, getRewards } = this.props;

    const {
      statusFilter,
      orderByDate,
      showEditStatusAlert,
      showDeleteCardTypeAlert,
      item,
      showEdit,
      isEditing,
      type,
      deleteCardMessage,
      showUploadCsv,
    } = this.state;

    return (
      <CardListView
        list={cards}
        item={item}
        type={type}
        isEditing={isEditing}
        showEdit={showEdit}
        statusFilter={statusFilter}
        orderByDate={orderByDate}
        currentPage={currentPage}
        pages={pages}
        loading={loading}
        loadingSave={loadingSave}
        getCards={getCards}
        getRewards={getRewards}
        showEditStatusAlert={showEditStatusAlert}
        showDeleteCardTypeAlert={showDeleteCardTypeAlert}
        deleteCardMessage={deleteCardMessage}
        switchUploadCsvModal={this.switchUploadCsvModal}
        showUploadCsv={showUploadCsv}
        onSubmit={this.onSubmit}
        onFilter={this.onFilter}
        onSearch={this.onSearch}
        onOrderByDate={this.onOrderByDate}
        changePage={this.changePage}
        onEditStatus={this.onEditStatus}
        approveCardType={this.approveCardType}
        switchModal={this.switchModal}
        deleteCardModal={this.deleteCardModal}
        deleteCardAndSendNotification={this.deleteCardAndSendNotificationFunc}
        changeDeleteCardMessage={this.changeDeleteCardMessage}
        showUploadAlert={this.showUploadAlertFunc}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.auth.profile,
    cards: state.card.cards,
    count: state.card.count,
    currentPage: state.card.currentPage,
    pages: state.card.pages,
    loading: state.card.loading,
    loadingSave: state.card.loadingSave,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCards: params => dispatch(getCardsRequest(params)),
    createCard: params => dispatch(createCardRequest(params)),
    editCard: params => dispatch(editCardsRequest(params)),
    approveCard: params => dispatch(approveCardsRequest(params)),
    deleteCardAndSendNotification: params => dispatch(deleteCardAndSendNotificationRequest(params)),
    showUploadAlert: () => dispatch(showAlert('Uploaded successful', { variant: 'success' })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);
