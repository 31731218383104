import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { replace } from '../../modules/history';
import Logo from '../../../assets/media/images/Logo.png';
import styled from 'styled-components';

const Content = styled.div``;

export default class ResetPassView extends Component {
  static propTypes = {
    resetPassword: PropTypes.func.isRequired,
  };

  submit = (values) => {
    const { resetPassword } = this.props;
    const { password } = values;

    resetPassword(password);
  };

  render() {
    const { loading } = this.props;

    const initialValues = {
      password: '',
      confirmPassword: '',
    };

    return (
      <Container className="h-100 justify-content-center align-items-center pt-5">
        <Row>
          <Col
            xs={12}
            sm={{ span: 8, offset: 2 }}
            md={{ span: 4, offset: 4 }}
            ls={{ span: 4, offset: 4 }}
          >
            <Content className="pb-5 text-center">
              <img src={Logo} alt="YieldBucks logo" width="260px" />
            </Content>

            <div className="pb-5 pt-5 text-center">
              <h1>Reset password</h1>
              <p style={{ color: '#7B7B7B' }}>
                Your new password should be different from previous used password.
              </p>
            </div>

            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={this.submit}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .required('Required')
                  .min(8, 'The password must have more than eight characters'),
                confirmPassword: Yup.string().test(
                  'match',
                  'Passwords must match',
                  function (confirmPassword) {
                    return confirmPassword === this.parent.password;
                  },
                ),
              })}
            >
              {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-5" controlId="password">
                    <Form.Label as="h4">Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password && touched.password && errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-5" controlId="confirmPassword">
                    <Form.Label as="h4">Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm password"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="button-group" className="text-center">
                    <Button
                      variant="primary mb-5 mt-5"
                      size="lg"
                      block
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? 'Loading…' : 'Reset Password'}
                    </Button>
                    <Button
                      className="custom-btn-link"
                      variant="link"
                      onClick={() => replace('/login')}
                    >
                      Back to Login
                    </Button>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    );
  }
}
