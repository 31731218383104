import React, { Component } from 'react';
import styled from 'styled-components';
import Header from './Header';
import { headerHeight } from '../modules/theme';
import '../components/styles.css';

const Content = styled.div`
  padding-top: ${headerHeight}px;
`;

export default class MenuPage extends Component {
  render() {
    const { profile, onLogout } = this.props;

    return (
      <div>
        <Header profile={profile} logOut={onLogout} />
        <Content className="pageContent">{this.props.children}</Content>
      </div>
    );
  }
}
