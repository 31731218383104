import React, { Component } from 'react';
import Moment from 'moment';
import { Button, Row, Col, Modal } from 'react-bootstrap';

export default class ShowDetailsModal extends Component {
  render() {
    const { show, onClose, values } = this.props;

    if (!values) {
      return null;
    }

    return (
      <Modal show={show} onHide={onClose} size="lg" centered backdrop="static" keyboard={false}>
        <Modal.Header
          closeButton
          style={{
            padding: '25px 25px 10px 25px',
          }}
        >
          <h3 className="modal-header-title-30mrg">Support/Feedback Request Details</h3>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-2 p-4">
            <Col md>
              <h4>Name</h4>
              <p>{values.name}</p>
            </Col>
            <Col md>
              <h4>Email</h4>
              <p>{values.email}</p>
            </Col>
            <Col md>
              <h4>Date</h4>
              <p>{Moment(values.createdAt).format('LLL')}</p>
            </Col>
          </Row>
          <Row className="g-2 p-4">
            <Col md={12}>
              <h4>Message</h4>
              <p>{values.message}</p>
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Button variant="primary mb-5 mt-5" size="lg" type="button" onClick={onClose}>
              Close
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}
