import React, { Component } from 'react';
import Moment from 'moment';
import styled from 'styled-components';
import { Table, Form, Row, Col, Dropdown, Container, Alert } from 'react-bootstrap';
import { replace } from '../../modules/history';
import Pagination from '../../components/Pagination';
import InputSearch from '../../components/InputSearch';
import { UserStatus } from '../../constants/index';
import ModalConfirm from '../../components/ModalConfirm';
import CustomSpinner from '../../components/CustomSpinner';
import Icon from '../../components/Icon';
import { Colors } from '../../modules/theme';

const ButtonShowPage = styled.button`
  background-color: transparent;
  border: none;
  aling-items: center;
  color: #33415c;
  justify-self: start;
  margin-right: 10px;
`;

const ALERT_MESSAGES = {
  block: {
    title: 'Block User',
    message: 'Are you sure that want block the user?',
    successText: 'Block',
  },
  unblock: {
    title: 'Unblock User',
    message: 'Are you sure that want unblock the user?',
    successText: 'Unblock',
  },
};

export default class UserListView extends Component {
  showPageUser = id => replace(`/dashboard/users/${id}`);

  renderItem = (item, key) => {
    const { switchModal, switchDeleteModal } = this.props;
    const { uuid, firstName, lastName, createdAt, isBlocked } = item;
    return (
      <tr key={key}>
        <td>
          <ButtonShowPage className="username-list" onClick={() => this.showPageUser(uuid)}>
            {`${firstName} ${lastName}`}
          </ButtonShowPage>
        </td>
        <td>{Moment(createdAt).format('LLL')}</td>
        <td>{isBlocked ? 'Blocked' : 'Active'}</td>
        <td>
          {!isBlocked ? (
            <ButtonShowPage onClick={() => switchModal(uuid, { isBlocked: true }, true, 'block')}>
              <Row>
                <Icon
                  name="lock"
                  fontSize={18}
                  color={Colors.secondary}
                  padding="0px 10px 0px 0px"
                />
                <h4 className="text-secondary">Block</h4>
              </Row>
            </ButtonShowPage>
          ) : (
            <ButtonShowPage
              onClick={() => switchModal(uuid, { isBlocked: false }, true, 'unblock')}
            >
              <Row>
                <Icon
                  name="unlock"
                  fontSize={18}
                  color={Colors.secondary}
                  padding="0px 10px 0px 0px"
                />
                <h4 className="text-secondary">Unblock</h4>
              </Row>
            </ButtonShowPage>
          )}

          <ButtonShowPage onClick={() => switchDeleteModal(uuid, true)}>
            <Row className="ml-3">
              <Icon name="times" fontSize={18} color={Colors.red} padding="0px 10px 0px 0px" />
              <h4 className="text-danger">Delete</h4>
            </Row>
          </ButtonShowPage>
        </td>
      </tr>
    );
  };

  render() {
    const {
      users,
      currentPage,
      pages,
      orderByDate,
      statusFilter,
      loading,
      onSearch,
      onFilter,
      onOrderByDate,
      changePage,
      onEditStatus,
      switchModal,
      showAlert,
      showRemoveAlert,
      onDeleteUser,
      type,
      currentId,
      switchDeleteModal,
    } = this.props;

    return (
      <Container>
        <Row>
          <Col className="pt-5 row-spc-between" xs={12} sm={12} md={12} ls={12}>
            <h1 className="h1-list">User List </h1>
          </Col>

          <Col className="pt-5 justify-content-start" xs={12} sm={6} md={8} ls={8}>
            <Form.Group as={Row} className="mb-3" controlId="filter">
              <Col sm="12">
                <h4 className="float-left mr-3 mt-2">Filter</h4>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-message-type">
                    {statusFilter == '' ? 'All' : statusFilter}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {UserStatus.map(({ label, value }, key) => (
                      <Dropdown.Item
                        key={key}
                        active={statusFilter === value}
                        onClick={() => {
                          onFilter(value);
                        }}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        onFilter(null);
                      }}
                    >
                      Remove filters
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Form.Group>
          </Col>

          <Col className="pt-5" xs={12} sm={6} md={4} ls={4}>
            <Form.Group as={Row} className="mb-3" controlId="search">
              <Col sm="12" className="justify-content-end">
                <InputSearch
                  onChange={search => {
                    onSearch(search);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col className="pt-5 justify-content-start" xs={12} sm={12} md={12} ls={12}>
            <Table responsive bordered>
              <thead>
                <tr>
                  <th>Username</th>

                  <th>
                    <span>Date of registration</span>
                    <Icon
                      name="sort"
                      fontSize={15}
                      color={Colors.white}
                      padding="0px 0px 0px 10px"
                      onClick={() => {
                        const order = orderByDate === 'desc' ? 'asc' : 'desc';
                        onOrderByDate(order);
                      }}
                    />
                  </th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {users && !loading && users.map((item, key) => this.renderItem(item, key))}
              </tbody>
            </Table>

            {users && !loading && users.length === 0 && (
              <Alert variant="warning" className="input-text">
                There are currently no users
              </Alert>
            )}

            {loading && <CustomSpinner />}

            <ModalConfirm
              title={type ? ALERT_MESSAGES[type].title : ''}
              message={type ? ALERT_MESSAGES[type].message : ''}
              successText={type ? ALERT_MESSAGES[type].successText : ''}
              show={showAlert}
              onCancel={() => switchModal(null, null, false, '')}
              onSuccess={onEditStatus}
            />

            <ModalConfirm
              title="Delete User"
              message="Are you sure that want delete the user?"
              successText="Delete"
              show={showRemoveAlert}
              onCancel={() => switchDeleteModal(null, false)}
              onSuccess={() => {
                onDeleteUser(currentId);
                switchDeleteModal(null, false);
              }}
            />

            {!loading && (
              <Pagination currentPage={currentPage} changePage={changePage} totalPages={pages} />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
