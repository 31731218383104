export const headerHeight = 81;

export const appColor = '#00b4d5';

export const easing = 'cubic-bezier(0.35, 0.01, 0.77, 0.34);';

export default {
  breakpoints: {
    xs: 0,
    ix: 400,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1920,
  },
  palette: {
    primary: appColor,
  },
  button: {
    borderRadius: {
      xs: 4,
      lg: 28,
      xl: 32,
    },
    padding: {
      lg: [12, 28],
      xl: [14, 32],
    },
  },
};
export const Colors = {
  blueLight: '#e5ebf2',
  blueLight1: '#E9F1FC',
  blue: '#0084EA',
  blue1: '#173264',
  blue2: '#425268',
  black: '#000000',
  red: '#FE3636',
  grayLight: '#F6F6F6',
  gray: '#979695',
  gray2: '#DADADA',
  transparent: 'rgba(0,0,0,0)',
  white: '#fff',
  success: '#28a745',
  secondary: '#6c757d',
  warning: '#ffc107'
};
