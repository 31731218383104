import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { appColor, Colors, headerHeight } from '../modules/theme';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Lato:400,700');

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    height: 100%;
  }

  body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px; /* stylelint-disable unit-blacklist */
    margin: 0;
    min-height: 100vh;
    padding: 0;
    background-color: #E5E5E5;
  }

  .invalid-feedback {
    display: block !important;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .full-height {
    height: 100vh;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .field{
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    height: calc(2em + 0.75rem + 4px) !important;
    font-size: 1.5rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .btn-primary {
    background-color: #4886FF !important;
    text-align: center !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: 0.3px !important;
    color: #FFFFFF !important;
  }

  .custom-tab-active {
    border-bottom: solid 3px #4886FF !important;
  }

  .btn-tab {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
    font-size: 15px !important;
    color: #000000 !important;
  }

  .btn-link.focus, .btn-link:focus {
      text-decoration: none !important;
  }

  .btn-link > .btn.focus, .btn:focus {
      outline: 0;
      box-shadow: none !important;
  }

  .btn-link:hover {
    text-decoration: none !important;
  }

  .custom-btn-link {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    line-height: 31px !important;
    color: #0F1821 !important;
  }

  p { 
    font-size: 12px;
    color: black;
  }

  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: black;
    width: 340px;
  }


  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 51px;
    color: #0F1821;
  }

  .alert-success {
    background-color: #28a745 !important;
    border: 1px solid #28a745 !important;
    color: #ffffff !important;
  }

  .alert-warning {
    background-color: #ffc107 !important;
    border: 1px solid #ffc107 !important;
  }

  .alert-danger {
    background-color: #dc3545 !important;
    border: 1px solid #dc3545 !important;
    color: #ffffff !important;
  }

  .alert-info {
    background-color: #4886FF !important;
    border: 1px solid #4886FF !important;
  }

  .alert-dark {
    background-color: #343a40 !important;
    border: 1px solid #343a40 !important;
  }

  .alert-default {
    background-color: #4886FF !important;
    border: 1px solid #4886FF !important;
  }

  .custom-badge {
    font-size: 17px !important;
    margin: 0.25em 0.5em !important;
    padding: 0.35em 1em !important;
  }

/* Black blue */

  
  .kCYxzF {
    padding-right: 7px;
    padding-top: 14px;
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 0px;
    color: #4886FF;
    font-size: 15px;
    z-index: 100;
  }

  .form-control {
    border: 1px solid rgb(237, 237, 237) !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
  }
 

  .navbar-collapse {
    margin-left: 4rem;
  }

  .forms-control {
    margin-top: 10px;
    height: 38px !important;
    font-size: 14px !important;
    background: #F8FBFF !important;
    border: none !important;
    color: #425268 !important;
  }

  .textarea{
    display: block !important;
    width: 100% !important;
    height: 120px !important; 
    padding: .375rem .75rem !important;
    color: #425268 !important;
    font-family: Open Sans, FontAwesome, 'Material Icons' !important;
    font-size: 1.5rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    border-radius: .25rem !important;
    outline-color: #AAD7FF !important;    
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out  !important;    
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .is-invalid-select {
    background-image: none !important;
  }

  .icon-button {
    padding-right: 3px;
  }

  .button-inline {
    margin-right: 5px;
  }

  .text-button {
    text-align: center;
    margin-bottom: auto  !important;
    font-size: 18px  !important;
    font-weight: bold  !important;
    line-height: 25px  !important;
  }

  .text-tabs {
    text-align: center;
    margin-bottom: auto  !important;
    font-size: 14px  !important;
    font-weight: bold  !important;
    line-height: 25px  !important;
  }


  .text-title {
    font-style: normal  !important;
    font-weight: 600  !important;
    font-size: 24px  !important;
    line-height: 33px  !important;
  }



  .text-header {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }
  
  .pointer {
    cursor: pointer !important;
  }

  .button-rounded {
    border-radius: 50px;
    width: 20px;
    height: 20px;
    padding: 5px;
    color: white;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-text{
    font-style: normal;
    font-weight: normal;
    color: #425268;
    font-size: 14px !important;
    line-height: 19px;
  }

  .input-select{
    border: none !important;
    color: #0084EA  !important;
    font-weight: bold  !important;
  }

  .text-error {
    font-size: 12px; 
    margin-top: 5px;
    color: red;
  }

  .form-control-placeholdericon {
    font-family: Open Sans, FontAwesome, 'Material Icons';
  }

  .form-control{
    height: calc(2em + 0.75rem + 4px) !important;
    font-size: 1.5rem !important;
  }

  .textarea-autocomplete {
    width: 100%;
    border-radius: 5px !important;
    padding: 0rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgb(237,237,237);
    box-sizing: border-box !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .textarea-autocomplete:focus,
  .textarea-autocomplete:focus-visible {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
    outline: none;
  }

  .border-none {
    border: none !important;
  }

  .form-check {
    margin: 5px 0px;
  }

  .form-check-label {
    padding-right: 5px;
    padding-top: 1px;
    font-size: 14px;

  }
  .checkbox-filled {
    border-color: #92aad0;
    background-color: #92aad0;
  }

  tr {
    border-width: 1px;
    border-color: ${Colors.gray2};
    border-style: solid;
    border-radius: 40000px !important;
  }

  .invalid-feedback{
    font-size: 100% !important;
  }

  .button{
    font-size: 18px !important;
  }


  .headerWrapper { 
    background-color: ${Colors.white};
    height: ${headerHeight}px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200;
    padding-top: 10px;
  
    &:before {
      background-color: #f0f0f0;
      bottom: 0;
      content: '';
      height: 0.2rem;
      left: 0;
      position: absolute;
      right: 0;
    }
  }

  .inputBox  {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }

  .replaceImageText {
    color: white;
    cursor: pointer;
    position: absolute;
    top: 40%;
    background-color: rgba(178, 178, 178, 0.4);
    border-radius: 6px;
    padding: 5px;
    left: 33%;
  }

  .imageHolder {
    width: 150px;
    height: 150px;
    position: relative;
    overflow: hidden;
    border-radius: 100px;
  }

  .background-activated span {
    background-color: #B1D8B8 !important;
  }

  .background-deactivated span {
    background-color: #FFE3B8 !important;
  }

  .imageUser {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 10px;
    margin-right: 10px;
    object-fit: cover;
  }

  .pageContent {
    width: 100%;
    background-color: #F8F8F8;
    overflow: auto;
    position: relative;
    height: 100vh;
  }

  .h4-dropdown{ 
    color: ${Colors.blue2};
    font-weight: bold;
  }

  .text-current-plan {
    color: ${Colors.blue};
    font-weight: bold;
  }

  .navDropdownBox{
    width: 250px; 
    background: #F8FBFF; 
  }

  .menu-items {
    width: 95% !important;
    margin-top: 30px !important;
    padding-top: 50px !important;
    height: 150px !important;
    border: 2px solid ${Colors.blueLight}!important;
    border-radius: 4px !important;
    cursor: pointer !important;
  }

  .form-check-label {
    padding-left: 13px;
    padding-top: 1px;
    font-size: 15px;
  } 

  .label-check {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 27px !important;
  }

  .form-check-input {
    position: absolute;
    margin-top: 10px !important;
  }

  .menu-items-active {
    width: 95% !important;
    margin-top: 30px !important;
    padding-top: 50px !important;
    height: 150px !important;
    cursor: pointer !important;
    background: ${Colors.blueLight1};
  }

  .select-jobs {
    height: 30px !important;
    width: 110px !important;
    color: #fff !important;
    border-radius: 3px !important;
    border: none !important;
    font-weight: bold !important;
    font-size: 14px !important;
  }

  .select-jobs-newticket {
    background: #56D053 !important;
  }
  
  .select-jobs-research {
    background: #008080 !important;
  } 
  
  .select-jobs-submitted  {
    background: #3D2AB9 !important;
  }

  .select-jobs-quote  {
    background: #E445B4 !important;
  }

  .select-jobs-onhold  {
    background: #800080 !important;
  }
  
  .select-jobs-na  {
    background: #8BCDEF !important;
  }
  
  .select-jobs-voidcancel  {
    background: #808000 !important;
  }
  
  .select-jobs-voidduplicate  {
    background: #DC4B4B !important;
  }
  
  .select-jobs-voidlost  {
    background: #808080 !important;
  }
  
  .select-jobs-testticket  {
    background: #EB6A36 !important;
  }
   
 

  tbody {
    background-color: #FFFFFF;
  }

  th {
    background-color: #33415C;
  }

  tr {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    border-width: 2px;
    border-color: #E5E5E5;
    text-align: center;
    padding: 11px;
  }

  td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 34px;
    text-align: center;
    color: #0F1821;
  }

  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #E5E5E5 !important;
  }

  .table-bordered td, .table-bordered th {
    border: 1px solid #E5E5E5 !important;
  }

  .table td, .table th {
    padding: .75rem !important;
    vertical-align: inherit !important;
    border-top: 1px solid #dee2e6 !important;
}

  .btn-success {
    font-size: 16px !important;
    color: #4886FF !important;
    background-color: transparent !important;
    border-color: #4886FF !important;
  }

  .navbar {
    padding: 1.75rem 1rem !important;
    background-color: #FFFFFF !important;
  }

  .menu-navbar {
    position: fixed !important;
    width: 100% !important;
    z-index: 100 !important;
  }

  .a-navbar {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 25px !important;
    color: #000000 !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .h1-list {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 51px;
    color: #0F1821;
  }


  .user-card{
    padding: 30px;
    height:100%;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
  .username-card{
    font-weight: bold;
    font-size: 20px;
  }
  .username-list{
    font-weight: bold;
    color: #4886FF !important;
  }
  .h3-card{
    color:#808080;
    font-size:medium;
    margin-top:20px;
  
  }

  .subtitle-card{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 140.62%;
  }

  .rewards-card{
    color: #969696;
  }

  .date-card{
    font-size:medium;
  }
  .actions-card{
    display:flex;
    flex-direction:column;
    height:100px;
    aling-items:center;
    justify-content:space-between;
    margin-top:20px;
  }


  .a-blocked {
    margin-left: 40px;
    color: rgb(254, 54, 54);
    font-size: 19px;
    text-decoration-line: underline;
  }

  .modal-90w > .modal-dialog {
    width: 90% !important;
    max-width: 90% !important;
  }

  .modal-header-title{
    font-weight: bold;
    font-size:large;
    margin-bottom:50px;
  }

  .modal-header-title-30mrg{
    font-weight: bold;
    font-size:large;
  }

  .modal-body-title{
    font-weight: bold;
    font-size:medium;
    margin-bottom:20px;
  }
  .row-spc-between{
    display:flex;
    flex-direction:row;
    width:100%;
    aling-items:center;
    justify-content:space-between;
  }
  .navigate-active{
    border:solid 1px blue;
  }

  .filter{
    color:blue;  
  }

  .dropdown-scrollable-menu {
    max-height: 500px; 
    overflow-y: auto;
  }

`;

export default () => <GlobalStyle />;
