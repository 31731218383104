import React, { Component } from 'react';
import Moment from 'moment';
import styled from 'styled-components';
import { Table, Button, Form, Row, Col, Dropdown, Container, Alert, Modal } from 'react-bootstrap';
import { replace } from '../../modules/history';
import InputSearch from '../../components/InputSearch';
import Pagination from '../../components/Pagination';
import Icon from '../../components/Icon';
import { Colors } from '../../modules/theme';
import { CardsStatus } from '../../constants/index';
import ModalConfirm from '../../components/ModalConfirm';
import CustomSpinner from '../../components/CustomSpinner';
import CardFormModal from './CardFormModal';
import UploadCsvModal from './UploadCsvModal';

const ButtonAdd = styled.button`
  background-color: transparent;
  width: max-content;
  color: #fdb600;
  border: none;
  aling-items: center;
  justify-self: start;
  margin-right: 10px;
  text-aling: center;
  font-size: medium;
  font-weight: bold;
`;

const ButtonBlock = styled.button`
  background-color: transparent;
  border: none;
  aling-items: center;
  justify-self: end;
`;

const ButtonShowPage = styled.button`
  background-color: transparent;
  border: none;
  aling-items: center;
  color: #33415c;
  justify-self: start;
  margin-right: 10px;
`;

export default class CardListView extends Component {
  showPageUser = id => replace(`/dashboard/card-list/${id}`);

  renderAlert = (title, content, show, handleClose, successText, success) => (
    <Modal show={show} onHide={handleClose} animation={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{content}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={success}>
          {successText}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderCard = (
    { id, name, createdAt, status, bankName, bankAddress, rewards, source, url },
    key,
  ) => {
    const { switchModal, deleteCardModal } = this.props;
    return (
      <tr key={key}>
        <td>
          <ButtonShowPage className="username-list" onClick={() => this.showPageUser(id)}>
            {name}
          </ButtonShowPage>
        </td>
        <td>{bankName || '-'}</td>

        <td>{Moment(createdAt).format('LLL')}</td>

        <td>
          <Button variant="primary" disabled>
            {status}
          </Button>
        </td>
        <td>
          <ButtonShowPage
            onClick={() => {
              switchModal(
                id,
                {
                  id,
                  name,
                  createdAt,
                  status,
                  bankName,
                  bankAddress,
                  rewards,
                  source,
                  url,
                },
                false,
                true,
                true,
              );
            }}
            style={{
              display: status == 'Block' ? 'none' : 'auto',
            }}
          >
            <Icon name="pencil-alt" fontSize={15} color={Colors.blue2} padding="0px 10px 0px 0px" />
            Edit
          </ButtonShowPage>

          {source != 'ccStack' && status != 'deleted' && (
            <ButtonBlock
              onClick={() => {
                deleteCardModal({ id, showDeleteCardTypeAlert: true });
              }}
              style={{
                color: Colors.red,
              }}
            >
              <Icon
                name="times"
                fontSize={15}
                color={status == 'Block' ? '#86D189' : Colors.red}
                padding="0px 10px 0px 0px"
              />
              Remove
            </ButtonBlock>
          )}

          {status == 'pending' && (
            <ButtonShowPage
              onClick={() => {
                switchModal(id, { status: 'active' }, true, false, false, 'approve');
              }}
              style={{
                color: Colors.success,
              }}
            >
              <Icon name="check" fontSize={15} color={Colors.success} padding="0px 10px 0px 0px" />
              Approve
            </ButtonShowPage>
          )}
        </td>
      </tr>
    );
  };

  render() {
    const {
      list,
      pages,
      type,
      currentPage,
      orderByDate,
      onOrderByDate,
      onFilter,
      statusFilter,
      onSearch,
      changePage,
      loading,
      loadingSave,
      onEditStatus,
      approveCardType,
      switchModal,
      showEditStatusAlert,
      showDeleteCardTypeAlert,
      deleteCardModal,
      deleteCardAndSendNotification,
      changeDeleteCardMessage,
      deleteCardMessage,
      showEdit,
      item,
      isEditing,
      showApprovedAlert,
      onSubmit,
      switchUploadCsvModal,
      showUploadCsv,
      showUploadAlert,
    } = this.props;

    const ALERT_MESSAGES = {
      approve: {
        title: 'Approve card',
        message: 'Are you sure that want approve the card?',
        successText: 'Approve',
        successAction: approveCardType,
      },
      delete: {
        title: 'Delete card',
        message: 'Are you sure that want delete the card?',
        successText: 'Delete',
        successAction: onEditStatus,
      },
    };

    return (
      <Container>
        <Row>
          <Col className="pt-5 row-spc-between" xs={12} sm={12} md={12} ls={12}>
            <h1 className="h1-list">Card List</h1>

            <div className="p-3">
              <Button
                variant="primary"
                className="rounded-pill mx-2 pl-5 pr-5"
                onClick={() => switchUploadCsvModal(true)}
              >
                Upload CSV
              </Button>
              <Button
                variant="primary"
                className="rounded-pill pl-5 pr-5"
                onClick={() => switchModal(null, { url: '', rewards: [] }, false, true)}
              >
                Add new
              </Button>
            </div>
          </Col>

          <Col className="pt-5 justify-content-start" xs={12} sm={6} md={8} ls={8}>
            <Form.Group as={Row} className="mb-3" controlId="filter">
              <Col sm="12">
                <h4 className="float-left mr-3 mt-2">Filter</h4>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-message-type">
                    {statusFilter || 'All'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {CardsStatus.map(({ label, value }, key) => (
                      <Dropdown.Item
                        key={key}
                        active={statusFilter === value}
                        onClick={() => {
                          onFilter(value);
                        }}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        onFilter('');
                      }}
                    >
                      Remove filters
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Form.Group>
          </Col>

          <Col className="pt-5" xs={12} sm={6} md={4} ls={4}>
            <Form.Group as={Row} className="mb-3" controlId="search">
              <Col sm="12" className="justify-content-end">
                <InputSearch
                  onChange={search => {
                    onSearch(search);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Table responsive bordered>
              <thead>
                <tr>
                  <th>Card Name</th>
                  <th>Bank Name</th>
                  <th>
                    <span>Date of registration</span>
                    <Icon
                      name="sort"
                      fontSize={15}
                      color={Colors.white}
                      padding="0px 0px 0px 10px"
                      onClick={() => {
                        const order = orderByDate === 'desc' ? 'asc' : 'desc';
                        onOrderByDate(order);
                      }}
                    />
                  </th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {list && !loading && list.map((card, key) => this.renderCard(card, key))}
              </tbody>
            </Table>

            {loading && <CustomSpinner />}

            {list && !loading && list.length === 0 && (
              <Alert variant="warning" className="input-text">
                There are currently no cards
              </Alert>
            )}

            {/* Alerts */}
            <ModalConfirm
              title={type ? ALERT_MESSAGES[type].title : ''}
              successText={type ? ALERT_MESSAGES[type].successText : ''}
              message={type ? ALERT_MESSAGES[type].message : ''}
              show={showEditStatusAlert}
              onCancel={() => switchModal(null, false)}
              onSuccess={() => ALERT_MESSAGES[type].successAction()}
            />

            <Modal
              show={showDeleteCardTypeAlert}
              onHide={() => deleteCardModal({ showDeleteCardTypeAlert: false })}
              animation={false}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Delete Card Type</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>What is the reason for declining this card? </p>
                <Form.Control
                  onChange={changeDeleteCardMessage}
                  deleteCardMessage={deleteCardMessage}
                  as="textarea"
                  rows={3}
                />
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={() => deleteCardModal({ showDeleteCardTypeAlert: false })}
                >
                  Cancel
                </Button>
                <Button variant="danger" size="lg" onClick={deleteCardAndSendNotification}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
            {showApprovedAlert &&
              this.renderAlert(
                'Approve User',
                'Are you sure that want Approve user?',
                showApprovedAlert,
                () => this.setState({ showApprovedAlert: false }),
                'Approve',
                this.approved,
              )}

            <CardFormModal
              show={showEdit}
              initialValues={item}
              isEditing={isEditing}
              onSubmit={onSubmit}
              loading={loadingSave}
              onClose={() => switchModal(false, {}, false, false)}
            />

            <UploadCsvModal
              show={showUploadCsv}
              initialValues={item}
              isEditing={isEditing}
              onSubmit={onSubmit}
              loading={loadingSave}
              onClose={() => switchUploadCsvModal(false)}
              showUploadAlert={showUploadAlert}
            />

            {!loading && (
              <Pagination currentPage={currentPage} changePage={changePage} totalPages={pages} />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
