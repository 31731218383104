import React, { Component } from 'react';
import NewFeaturesView from './NewFeaturesView';
import { getNewFeaturesRequest } from '../../actions/index';
import { connect } from 'react-redux';

const elementsPerPage = 10;

class NewFeaturesContainer extends Component {
  state = {
    currentPage: 1,
    search: '',
    orderByDate: 'desc',
  };

  componentDidMount() {
    const { getAll } = this.props;

    getAll({ elementsPerPage });
  }

  changePage = (currentPage) => {
    this.setState({ currentPage }, () => {
      const { getAll } = this.props;
      const { search, orderByDate } = this.state;

      getAll({
        currentPage,
        elementsPerPage,
        search,
        orderByDate,
      });
    });
  };

  onSearch = (search) => {
    this.setState({ search }, () => {
      const { getAll } = this.props;
      const { orderByDate } = this.state;

      getAll({ elementsPerPage, search, orderByDate });
    });
  };

  onOrderByDate = (orderByDate) => {
    this.setState({ orderByDate }, () => {
      const { getAll } = this.props;
      const { search } = this.state;

      getAll({ elementsPerPage, search, orderByDate });
    });
  };

  render() {
    const { list, pages, currentPage, loading } = this.props;

    const { orderByDate } = this.state;

    return (
      <NewFeaturesView
        list={list}
        currentPage={currentPage}
        pages={pages}
        orderByDate={orderByDate}
        loading={loading}
        onSearch={this.onSearch}
        onOrderByDate={this.onOrderByDate}
        changePage={this.changePage}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    list: state.newFeature.list,
    pages: state.newFeature.pages,
    currentPage: state.newFeature.currentPage,
    loading: state.newFeature.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAll: (params) => dispatch(getNewFeaturesRequest(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewFeaturesContainer);
