import React, { Component } from 'react';
import Moment from 'moment';
import styled from 'styled-components';
import {
  Table,
  Button,
  Form,
  FormControl,
  Row,
  Card,
  Col,
  Dropdown,
  Container,
  Badge,
  Alert,
  Modal,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { replace } from '../../modules/history';
import Pagination from '../../components/Pagination';
import Icon from '../../components/Icon';
import InputSearch from '../../components/InputSearch';
import { MessageTypes, MapMessageTypesKeys, MapMessageTypesExamples } from '../../constants/index';
import { Colors } from '../../modules/theme';
import Switch from '../../components/Switch';
import Textarea from '../../components/Textarea';
import replaceAll from '../../utils/replaceAll';

export default class NotificationFormModal extends Component {
  render() {
    const { show, isEditing, onClose, onSubmit, initialValues, loading } = this.props;

    return (
      <Modal show={show} onHide={onClose} size="lg" centered backdrop="static" keyboard={false}>
        <Modal.Header
          closeButton
          style={{
            padding: '25px 25px 10px 25px',
          }}
        >
          <h3 className="modal-header-title-30mrg">{`${isEditing ? 'Edit' : 'Add'
            } Notification`}</h3>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={isEditing}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Required'),
              messageType: Yup.string().required('Required'),
              message: Yup.string().required('Required'),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isValid,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row className="g-2 p-4">
                    <Col md>
                      <Form.Group className="mb-5" controlId="title">
                        <Form.Label as="h4">Title*</Form.Label>

                        <Form.Control
                          type="text"
                          name="title"
                          placeholder="Title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.title && touched.title && errors.title}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-5" controlId="messageType">
                        <Form.Label as="h4">Message Type*</Form.Label>
                        <Form.Control
                          as="select"
                          value={values.messageType}
                          onChange={(evt) => {
                            handleChange(evt);
                            setFieldValue('message', '');
                          }}
                          onBlur={handleBlur}
                          aria-label="Select"
                        >
                          <option value="">Select</option>
                          {MessageTypes.map(({ label, value }, key) => (
                            <option value={value} key={key}>
                              {label}
                            </option>
                          ))}
                        </Form.Control>

                        <Form.Control.Feedback type="invalid">
                          {errors.messageType && touched.messageType && errors.messageType}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="filter">
                        <Form.Label column sm="2">
                          <h4>Active</h4>
                        </Form.Label>
                        <Col sm="10">
                          <Switch
                            isChecked={values.active}
                            onChange={() => {
                              setFieldValue('active', !values.active);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col md>
                      <Form.Group className="mb-5" controlId="message">
                        <Form.Label as="h4">Message*</Form.Label>

                        <Textarea
                          value={values.message}
                          onChange={(value) => setFieldValue('message', value)}
                          higlights={
                            values.messageType ? MapMessageTypesKeys[values.messageType] : []
                          }
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.message && touched.message && errors.message}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          To add a field, type something starting with ":"
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        {values.messageType ? (
                          <div>
                            <h4>Avaliable variables</h4>
                            <Row className="justify-content-start" noGutters={true}>
                              {MapMessageTypesKeys[values.messageType].map((label) => (
                                <Badge className="custom-badge" variant="primary">
                                  {label}
                                </Badge>
                              ))}
                            </Row>
                            {values.message ? (
                              <div className="mt-3">
                                <h4>Example Message</h4>
                                <Alert className="mt-3" variant="secondary">
                                  <p>
                                    {replaceAll(
                                      values.message,
                                      MapMessageTypesExamples[values.messageType],
                                    )}
                                  </p>
                                </Alert>
                              </div>
                            ) : (
                              <div className="mt-3">
                                <h4>Example Message</h4>
                                <Alert variant="secondary">
                                  <p>Write on message field to view example message </p>
                                </Alert>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <h4>Avaliable variables</h4>
                            <div>
                              <p>Select a message type to view available variables</p>
                            </div>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center">
                    <Button variant="primary mb-5 mt-5" size="lg" type="submit" disabled={loading}>
                      {loading ? 'Loading…' : 'Submit'}
                    </Button>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}
