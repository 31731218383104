import React, { Component } from 'react';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { store } from 'store/';
import DropZone from '../../components/DropZone';

export default class UploadCsvModal extends Component {
  handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/sample.csv';
    link.download = 'sample.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const { show, onClose, showUploadAlert } = this.props;
    const { token } = store.getState().auth;

    return (
      <Modal show={show} onHide={onClose} className="modal-90w" centered keyboard={false}>
        <Modal.Header
          closeButton
          style={{
            padding: '25px 25px 10px 25px',
          }}
        >
          <h3 className="modal-header-title-30mrg">Upload CSV</h3>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-2 p-4">
            <Col md>
              <Form.Group className="mb-2" controlId="title">
                <Form.Label as="h4">CSV*</Form.Label>
                <DropZone
                  folder="csv"
                  token={token}
                  handleSuccess={() => {
                    onClose(false);
                    showUploadAlert();
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* <Row className="justify-content-md-center">
            <Button variant="link mb-5 mt-5" size="lg" type="button" onClick={this.handleDownload}>
              Download Sample CSV
            </Button>
          </Row> */}
        </Modal.Body>
      </Modal>
    );
  }
}
