/**
 * @module Sagas/User
 * @desc User
 */

import { put, call } from 'redux-saga/effects';
import {
  getUsersRequest as getUsers,
  getUsersSuccess,
  getUsersFailure,
  getUserSuccess,
  getUserFailure,
  editUserSuccess,
  editUserFailure,
  deleteUserSuccess,
  deleteUserFailure,
  showAlert,
} from 'actions/index';

import { push } from '../modules/history';

export function* getUsersRequest(api, action) {
  const { status, data } = yield call(api.getUsers, action.payload);
  if (status === 200) {
    yield put(getUsersSuccess(data.data));
  } else {
    const { error } = data;
    yield put(showAlert(error.message, { variant: 'danger' }));
    yield put(getUsersFailure());
  }
}

export function* getUserRequest(api, action) {
  const { status, data } = yield call(api.getUser, action.payload.id);
  if (status === 200) {
    yield put(getUserSuccess(data.data));
  } else {
    const { error } = data;
    yield put(showAlert(error.message, { variant: 'danger' }));
    yield put(getUserFailure());
  }
}

export function* editUserRequest(api, action) {
  const { id, params, currentStatusFilter } = action.payload;

  const { status, data } = yield call(api.editUser, id, params);

  if (status === 200) {
    yield put(editUserSuccess());
    yield put(showAlert('User Edited', { variant: 'success' }));
    yield put(getUsers({ statusFilter: currentStatusFilter || 'Active' }));
    push('/dashboard/users');
  } else {
    const { error } = data;
    yield put(showAlert(error.message, { variant: 'danger' }));
    yield put(editUserFailure());
  }
}

export function* deleteUserRequest(api, action) {
  const { id } = action.payload;

  const { status, data } = yield call(api.deleteUser, id);

  if (status === 200) {
    yield put(deleteUserSuccess());
    yield put(showAlert('User Deleted', { variant: 'success' }));
    yield put(getUsers({}));
    push('/dashboard/users');
  } else {
    const { error } = data;
    yield put(showAlert(error.message, { variant: 'danger' }));
    yield put(deleteUserFailure());
  }
}
