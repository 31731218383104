import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem;
`;

const CustomSpinner = () => {
  return (
    <SpinnerContainer>
      <Spinner
        animation="grow"
        size="lx"
        style={{ backgroundColor: '#4886FF', width: '5rem', height: '5rem' }}
      />
    </SpinnerContainer>
  );
};

export default CustomSpinner;
