import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../actions/index';
import UnauthorizeView from './UnauthorizeView';

class UnauthorizeContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onLogin: PropTypes.func.isRequired,
  };

  render() {
    const { onLogout } = this.props;

    return <UnauthorizeView onLogout={onLogout} />;
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    onLogout: () => dispatch(logout()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UnauthorizeContainer);
