import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { recoverPassword } from '../../actions/index';
import RecoverPassView from './RecoverPassView';

class RecoverPassContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onRecoverPassword: PropTypes.func.isRequired,
  };

  render() {
    const { onRecoverPassword, loading } = this.props;

    return <RecoverPassView recoverPassword={onRecoverPassword} loading={loading} />;
  }
}

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onRecoverPassword: (email) => dispatch(recoverPassword(email)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassContainer);
