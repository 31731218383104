const replaceAll = (text, flags) => {
  let result = text;

  Object.entries(flags).forEach(([key, value]) => {
    result = result.split(`<${key}>`).join(value);
  });

  return result;
};

export default replaceAll;
