/**
 * @module Actions/Feature
 * @desc Feature Actions
 */

import { createActions } from 'redux-actions';
import { ActionTypes } from '../constants/index';

export const { getNewFeaturesRequest, getNewFeaturesSuccess, getNewFeaturesFailure } =
  createActions({
    [ActionTypes.GET_NEW_FEATURES_REQUEST]: (data) => data,
    [ActionTypes.GET_NEW_FEATURES_SUCCESS]: (data) => data,
    [ActionTypes.GET_NEW_FEATURES_FAILURE]: () => ({}),
  });
