/**
 * Configuration
 * @module config
 */

const config = {
  name: 'Yield Bucks',
  description: 'Yield Bucks',
  BASE_URL: 'https://api.yieldbucks.com/api/v2',
  FIREBASE_API_KEY: 'AIzaSyBINeunklWWwr0N6tQMAdwjrscB8ZpQBdI',
  FIREBASE_AUTH_DOMAIN: 'yield-bucks.firebaseapp.com',
  FIREBASE_PROJECT_ID: 'yield-bucks',
  REGION: 'US',
};

export default config;
