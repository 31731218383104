import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from '../modules/helpers';
import { ActionTypes } from '../constants/index';

export const appState = {
  list: [],
  loading: false,
};

export default {
  allReward: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_ALL_REWARDS_REQUEST]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_ALL_REWARDS_SUCCESS]: (
        draft,
        {
          payload: {
            result,
            metadata: { totalPages, currentPage, totalItems },
          },
        },
      ) => {
        draft.list = result;
        draft.loading = false;
        draft.count = totalItems;
        draft.pages = totalPages;
        draft.currentPage = currentPage;
      },
      [ActionTypes.GET_ALL_REWARDS_FAILURE]: draft => {
        draft.loading = false;
      },
    },
    appState,
  ),
};
