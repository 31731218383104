import React, { Component } from 'react';
import NotificationView from './NotificationView';
import {
  getNotificationsRequest,
  createNotificationRequest,
  editNotificationRequest,
  deleteNotificationRequest,
  switchFormVisible,
} from '../../actions/index';
import { connect } from 'react-redux';

const elementsPerPage = 10;

class NotificationContainer extends Component {
  state = {
    currentPage: 1,
    search: '',
    messageType: '',
    orderByDate: 'desc',
  };

  componentDidMount() {
    const { getAll, currentTab } = this.props;

    getAll(currentTab, {});
  }

  switchTab = (currentTab) => {
    const { getAll } = this.props;
    getAll(currentTab, {});
  };

  changePage = (currentPage) => {
    this.setState({ currentPage }, () => {
      const { getAll, currentTab } = this.props;
      const { search, messageType, orderByDate } = this.state;

      getAll(currentTab, {
        currentPage,
        elementsPerPage,
        search,
        messageType,
        orderByDate,
      });
    });
  };

  onSearch = (search) => {
    this.setState({ search }, () => {
      const { getAll, currentTab } = this.props;
      const { messageType, orderByDate } = this.state;

      getAll(currentTab, { elementsPerPage, search, messageType, orderByDate });
    });
  };

  onFilter = (messageType) => {
    this.setState({ messageType }, () => {
      const { getAll, currentTab } = this.props;
      const { search, orderByDate } = this.state;

      getAll(currentTab, { elementsPerPage, search, messageType, orderByDate });
    });
  };

  onOrderByDate = (orderByDate) => {
    this.setState({ orderByDate }, () => {
      const { getAll, currentTab } = this.props;
      const { search, messageType } = this.state;

      getAll(currentTab, { elementsPerPage, search, messageType, orderByDate });
    });
  };

  render() {
    const {
      list,
      pages,
      currentPage,
      loading,
      loadingSave,
      item,
      showForm,
      isEditing,
      create,
      edit,
      erase,
      switchModalForm,
      currentTab,
    } = this.props;

    const { orderByDate, messageType } = this.state;

    return (
      <NotificationView
        currentTab={currentTab}
        list={list}
        currentPage={currentPage}
        pages={pages}
        orderByDate={orderByDate}
        messageType={messageType}
        loading={loading}
        loadingSave={loadingSave}
        item={item}
        showForm={showForm}
        isEditing={isEditing}
        create={create}
        edit={edit}
        erase={erase}
        switchModalForm={switchModalForm}
        switchTab={this.switchTab}
        onSearch={this.onSearch}
        onFilter={this.onFilter}
        onOrderByDate={this.onOrderByDate}
        changePage={this.changePage}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    list: state.notification.list,
    pages: state.notification.pages,
    currentPage: state.notification.currentPage,
    loading: state.notification.loading,
    loadingSave: state.notification.loadingSave,
    item: state.notification.item,
    showForm: state.notification.showForm,
    isEditing: state.notification.isEditing,
    currentTab: state.notification.currentTab,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAll: (type, params) => dispatch(getNotificationsRequest(type, params)),
    create: (data) => dispatch(createNotificationRequest(data)),
    edit: (id, data) => dispatch(editNotificationRequest(id, data)),
    erase: (id) => dispatch(deleteNotificationRequest(id)),
    switchModalForm: (showForm, isEditing, currentItem) =>
      dispatch(switchFormVisible(showForm, isEditing, currentItem)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer);
