import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { Container, Heading } from 'styled-minimal';
import Background from '../components/Background';
import { replace } from 'modules/history';

const StyledContainer = styled(Container)`
  align-items: center;
  text-align: center;

  h1,
  a {
    color: #fff;
    line-height: 1;
  }

  a {
    text-decoration: underline;
  }
`;

const NotFound = () => (
  <Background key="404">
    <StyledContainer layout="fullScreen" verticalPadding>
      <Heading fontSize={100}>500</Heading>
      <h2 style={{ textAlign: 'center', color: 'white' }}>Server Error</h2>
      <Button className="mt-3" onClick={() => replace('/')}>Reload</Button>
    </StyledContainer>
  </Background>
);

export default NotFound;
