import React from 'react';
import { Pagination } from 'react-bootstrap';

export default (props) => {
  const { currentPage, changePage, totalPages } = props;
  const nextPage = currentPage + 1;
  const backPage = currentPage - 1;

  return (
    <>
      {totalPages > 1 && (
        <Pagination>
          {currentPage > 1 && (
            <>
              <Pagination.Prev onClick={() => changePage(backPage)} />
              <Pagination.Item onClick={() => changePage(backPage)}>{backPage}</Pagination.Item>
            </>
          )}
          <Pagination.Item active>{currentPage}</Pagination.Item>
          {nextPage <= totalPages && (
            <>
              <Pagination.Item onClick={() => changePage(nextPage)}>{nextPage}</Pagination.Item>
              <Pagination.Next onClick={() => changePage(nextPage)} />
            </>
          )}
        </Pagination>
      )}
    </>
  );
};
