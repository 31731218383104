import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetPassword } from '../../actions/index';
import ResetPassView from './ResetPassView';

class ResetPassContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onResetPassword: PropTypes.func.isRequired,
  };

  resetPassword = (password) => {
    const {
      onResetPassword,
      history,
      location: { query },
    } = this.props;

    const { token } = query;

    onResetPassword(token, password);
  };

  render() {
    const { loading } = this.props;

    return <ResetPassView resetPassword={this.resetPassword} loading={loading} />;
  }
}

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onResetPassword: (token, password) => dispatch(resetPassword(token, password)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassContainer);
