/**
 * @module Actions/Notification
 * @desc Notification Actions
 */

import { createActions } from 'redux-actions';
import { ActionTypes } from '../constants/index';

export const {
  getNotificationsRequest,
  getNotificationsSuccess,
  getNotificationsFailure,
  createNotificationRequest,
  createNotificationSuccess,
  createNotificationFailure,
  editNotificationRequest,
  editNotificationSuccess,
  editNotificationFailure,
  deleteNotificationRequest,
  deleteNotificationSuccess,
  deleteNotificationFailure,
  switchFormVisible,
} = createActions({
  [ActionTypes.GET_NOTIFICATIONS_REQUEST]: (type, params) => ({ type, params }),
  [ActionTypes.GET_NOTIFICATIONS_SUCCESS]: (data) => data,
  [ActionTypes.GET_NOTIFICATIONS_FAILURE]: () => ({}),
  [ActionTypes.CREATE_NOTIFICATION_REQUEST]: (data) => data,
  [ActionTypes.CREATE_NOTIFICATION_SUCCESS]: () => ({}),
  [ActionTypes.CREATE_NOTIFICATION_FAILURE]: () => ({}),
  [ActionTypes.EDIT_NOTIFICATION_REQUEST]: (id, item) => ({ id, item }),
  [ActionTypes.EDIT_NOTIFICATION_SUCCESS]: () => ({}),
  [ActionTypes.EDIT_NOTIFICATION_FAILURE]: () => ({}),
  [ActionTypes.DELETE_NOTIFICATION_REQUEST]: (id) => ({ id }),
  [ActionTypes.DELETE_NOTIFICATION_SUCCESS]: () => ({}),
  [ActionTypes.DELETE_NOTIFICATION_FAILURE]: () => ({}),
  [ActionTypes.SWITCH_FORM_VISIBLE]: (showForm, isEditing, item) => ({ showForm, isEditing, item }),
});
