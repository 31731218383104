import React, { Component } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import styled from 'styled-components';
import config from '../config';

const DropzoneContainer = styled.div`
  margin-bottom: 34px;
  width: 100%;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 0px;
  margin-top: 30px;
  flex-direction: column;
`;

export default class DropZone extends Component {
  state = {
    status: 'empty',
  };

  getUploadParams = async ({ file, meta }) => {
    const { token, folder } = this.props;
    const fileName = file.name;

    try {
      const body = new FormData();
      body.append('file', file, fileName);

      return {
        url: `${config.BASE_URL}/uploads/${folder}`,
        headers: {
          authorization: `Bearer ${token}`,
          'x-region': config.REGION,
        },
        body,
      };
    } catch (error) {
      console.log(error);
    }
  };

  handleChangeStatus = ({ xhr }, status) => {
    if (status === 'done') {
      const { handleSuccess } = this.props;
      handleSuccess(JSON.parse(xhr.response));
    }
  };

  render() {
    const { height } = this.props;
    return (
      <DropzoneContainer>
        <Dropzone
          autoUpload={true}
          getUploadParams={this.getUploadParams}
          onChangeStatus={this.handleChangeStatus}
          multiple={false}
          canCancel={false}
          inputContent={(files, extra) =>
            extra.reject ? 'Only .csv files are allowed' : 'Drag CSV File or Click to Browse'
          }
          accept=".csv"
          styles={{
            dropzone: {
              height: height || '200px',
            },
          }}
        />
      </DropzoneContainer>
    );
  }
}
