import React, { Component } from 'react';
import UserListView from './UserListView';
import { getUsersRequest, editUserRequest, deleteUserRequest } from '../../actions/index';
import { connect } from 'react-redux';

const elementsPerPage = 10;

class UserListContainer extends Component {
  state = {
    currentPage: 1,
    search: '',
    orderByDate: 'desc',
    statusFilter: 'Active',
    showAlert: false,
    showRemoveAlert: false,
    currentId: null,
    item: null,
    type: '',
  };

  componentDidMount() {
    const { getAll } = this.props;
    const { currentPage, orderByDate, statusFilter } = this.state;
    getAll({ currentPage, elementsPerPage, statusFilter, orderByDate });
  }

  changePage = (currentPage) => {
    this.setState({ currentPage }, () => {
      const { getAll } = this.props;
      const { orderByDate, search } = this.state;

      getAll({
        currentPage,
        elementsPerPage,
        search,
        orderByDate,
      });
    });
  };

  onSearch = (search) => {
    this.setState({ search }, () => {
      const { getAll } = this.props;
      const { statusFilter, orderByDate } = this.state;

      getAll({ elementsPerPage, search, statusFilter, orderByDate });
    });
  };

  onFilter = (statusFilter) => {
    this.setState({ statusFilter }, () => {
      const { getAll } = this.props;
      const { search, orderByDate } = this.state;

      getAll({ elementsPerPage, search, statusFilter, orderByDate });
    });
  };

  onOrderByDate = (orderByDate) => {
    this.setState({ orderByDate }, () => {
      const { getAll } = this.props;
      const { search, statusFilter } = this.state;

      getAll({ elementsPerPage, search, statusFilter, orderByDate });
    });
  };

  onEditStatus = () => {
    const { editStatus, loadingSave } = this.props;
    const { currentId, statusFilter, item } = this.state;

    editStatus({
      id: currentId,
      params: item,
      currentStatusFilter: statusFilter,
    });

    if (!loadingSave) {
      this.setState({ currentId: null, item: null, showAlert: false, type: '' });
    }
  };

  switchModal = (currentId, item, showAlert, type) => {
    this.setState({ currentId, item, showAlert, type });
  };

  switchDeleteModal = (currentId, showRemoveAlert) => {
    this.setState({ currentId, showRemoveAlert });
  };

  render() {
    const { users, pages, currentPage, loading, loadingSave, deleteUser } = this.props;

    const { orderByDate, statusFilter, showAlert, showRemoveAlert, type, currentId } = this.state;

    return (
      <UserListView
        users={users}
        currentPage={currentPage}
        pages={pages}
        orderByDate={orderByDate}
        statusFilter={statusFilter}
        showAlert={showAlert}
        showRemoveAlert={showRemoveAlert}
        type={type}
        loading={loading}
        loadingSave={loadingSave}
        onSearch={this.onSearch}
        onFilter={this.onFilter}
        onOrderByDate={this.onOrderByDate}
        changePage={this.changePage}
        onEditStatus={this.onEditStatus}
        switchModal={this.switchModal}
        switchDeleteModal={this.switchDeleteModal}
        onDeleteUser={deleteUser}
        currentId={currentId}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.auth.profile,
    users: state.user.users,
    pages: state.user.pages,
    currentPage: state.user.currentPage,
    loading: state.user.loading,
    loadingSave: state.user.loadingSave,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAll: (params) => dispatch(getUsersRequest(params)),
    editStatus: (params) => dispatch(editUserRequest(params)),
    deleteUser: (id) => dispatch(deleteUserRequest(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer);
