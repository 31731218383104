import { handleActions } from '../modules/helpers';

import { ActionTypes } from '../constants/index';

export const userState = {
  isAuthenticated: false,
  profile: null,
  token: null,
  loading: false,
};

export default {
  auth: handleActions(
    {
      // login
      [ActionTypes.LOGIN]: (draft) => {
        draft.isAuthenticated = false;
        draft.profile = null;
        draft.token = null;
        draft.loading = true;
      },
      [ActionTypes.LOGIN_SUCCESS]: (draft, { payload: { profile, token } }) => {
        draft.isAuthenticated = true;
        draft.profile = profile;
        draft.token = token;
        draft.loading = false;
      },
      [ActionTypes.LOGIN_FAILURE]: (draft) => {
        draft.loading = false;
        draft.isAuthenticated = false;
      },
      // logout
      [ActionTypes.LOGOUT]: (draft) => {
        draft.loading = true;
      },
      [ActionTypes.LOGOUT_SUCCESS]: (draft) => {
        draft.isAuthenticated = false;
        draft.profile = null;
        draft.token = null;
        draft.loading = false;
      },
      [ActionTypes.LOGOUT_FAILURE]: (draft) => {
        draft.loading = false;
      },
      // recover password
      [ActionTypes.RECOVER_PASSWORD]: (draft) => {
        draft.loading = true;
      },
      [ActionTypes.RECOVER_PASSWORD_SUCCESS]: (draft) => {
        draft.loading = false;
      },
      [ActionTypes.RECOVER_PASSWORD_FAILURE]: (draft) => {
        draft.loading = false;
      },
      // reset password
      [ActionTypes.RESET_PASSWORD]: (draft) => {
        draft.loading = true;
      },
      [ActionTypes.RESET_PASSWORD_SUCCESS]: (draft) => {
        draft.loading = false;
      },
      [ActionTypes.RESET_PASSWORD_FAILURE]: (draft) => {
        draft.loading = false;
      },
      // update profile
      [ActionTypes.UPDATE_PROFILE]: (draft) => {
        draft.loading = true;
      },
      [ActionTypes.UPDATE_PROFILE_SUCCESS]: (draft, { payload }) => {
        draft.loading = false;
        draft.profile = { ...draft.profile, ...payload };
      },
      [ActionTypes.UPDATE_PROFILE_FAILURE]: (draft) => {
        draft.loading = false;
      },
      // update password
      [ActionTypes.UPDATE_PASSWORD]: (draft) => {
        draft.loading = true;
      },
      [ActionTypes.UPDATE_PASSWORD_SUCCESS]: (draft) => {
        draft.loading = false;
      },
      [ActionTypes.UPDATE_PASSWORD_FAILURE]: (draft) => {
        draft.loading = false;
      },
    },
    userState,
  ),
};
