import React, { Component } from 'react';
import Moment from 'moment';
import styled from 'styled-components';
import { Button, Form, FormControl, Row, Col, Container } from 'react-bootstrap';

import Icon from '../../components/Icon';

import { Formik } from 'formik';
import * as Yup from 'yup';

export default class ChangePasswordForm extends Component {
  render() {
    const { loading, initialValues, onSubmit } = this.props;

    return (
      <Container>
        <Row>
          <Col className="pt-5 row-spc-between" xs={12} sm={12} md={12} ls={12}>
            <h1 className="h1-list">Update Profile</h1>
          </Col>

          <Col className="pt-5 justify-content-start" xs={12} sm={12} md={12} ls={12}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={Yup.object().shape({
                displayName: Yup.string().required('Required'),
                phoneNumber: Yup.string().required('Required'),
              })}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isValid,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Row className="g-2 p-4">
                      <Col md>
                        <Form.Group className="mb-5" controlId="displayName">
                          <Form.Label as="h4">Name*</Form.Label>

                          <Form.Control
                            type="text"
                            name="displayName"
                            placeholder="Name"
                            value={values.displayName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.displayName && touched.displayName && errors.displayName}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-5" controlId="email">
                          <Form.Label as="h4">Email*</Form.Label>

                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={values.email}
                            onBlur={handleBlur}
                            disabled={true}
                          />
                        </Form.Group>
                      </Col>

                      <Col md>
                        <Form.Group className="mb-5" controlId="phoneNumber">
                          <Form.Label as="h4">Phone Number*</Form.Label>

                          <Form.Control
                            type="text"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="justify-content-md-center">
                      <Button variant="primary mb-5" size="lg" type="submit" disabled={loading}>
                        {loading ? 'Loading…' : 'Submit'}
                      </Button>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    );
  }
}
