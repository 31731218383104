/**
 * @module Sagas/Card
 * @desc Card
 */

import { put, call } from 'redux-saga/effects';
import { getAllRewardsSuccess, getAllRewardsFailure, showAlert } from 'actions/index';

export function* getAllRewardsRequest(api, action) {
  const { status, data } = yield call(api.getAllRewards, action.payload);
  if (status === 200) {
    yield put(getAllRewardsSuccess(data.data));
  } else {
    const { error } = data;
    yield put(showAlert(error.message, { variant: 'danger' }));
    yield put(getAllRewardsFailure());
  }
}
