/**
 * @module Sagas/User
 * @desc User
 */

import { put, call } from 'redux-saga/effects';

import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  recoverPasswordSuccess,
  recoverPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  updateProfileSuccess,
  updateProfileFailure,
  updatePasswordSuccess,
  updatePasswordFailure,
  showAlert,
} from 'actions/index';

import { push } from '../modules/history';

import reduxSagaFirebase from '../services/firebase';

export function* login(api, action) {
  const {
    payload: { email, password },
  } = action;
  try {
    const { user } = yield call(reduxSagaFirebase.auth.signInWithEmailAndPassword, email, password);

    const profile = {
      displayName: user.displayName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
    };

    const token = yield user.getIdToken(true);
    yield call(api.setAuthorization, token);

    yield put(loginSuccess({ profile, token }));
  } catch ({ message }) {
    yield put(loginFailure());
    yield put(showAlert(message, { variant: 'danger' }));
  }
}

export function* logout(api, action) {
  try {
    yield call(reduxSagaFirebase.auth.signOut);

    const token = null;

    yield call(api.setAuthorization, token);
    yield put(logoutSuccess());
    yield put(showAlert('Logout successful', { variant: 'success' }));
  } catch ({ message }) {
    yield put(logoutFailure());
    yield put(showAlert(message, { variant: 'danger' }));
  }
}

export function* recoverPassword(api, action) {
  const {
    payload: { email },
  } = action;
  const { status, data } = yield call(api.recoverPassword, email);

  if (status === 201) {
    yield put(showAlert('Email sent', { variant: 'success' }));
    yield put(recoverPasswordSuccess());
  } else {
    const { error } = data;
    yield put(recoverPasswordFailure());
    yield put(showAlert(error, { variant: 'danger' }));
  }
}

export function* resetPassword(action) {
  const {
    payload: { token, password },
  } = action;
  try {
    yield call(reduxSagaFirebase.auth.confirmPasswordReset, token, password);
    yield put(resetPasswordSuccess());
    yield put(showAlert('Your password has been reset', { variant: 'success' }));
    push('/login');
  } catch ({ message }) {
    yield put(resetPasswordFailure());
    yield put(showAlert(message, { variant: 'danger' }));
  }
}

export function* updateProfile(action) {
  const { payload } = action;
  const rsf = reduxSagaFirebase.auth;

  try {
    yield call(rsf.updateProfile, payload);
    yield put(showAlert('Your profile has been reset', { variant: 'success' }));
    yield put(updateProfileSuccess(payload));
  } catch ({ message }) {
    yield put(updateProfileFailure());
    yield put(showAlert(message, { variant: 'danger' }));
  }
}

export function* updatePassword(action) {
  const {
    payload: { password },
  } = action;
  const rsf = reduxSagaFirebase.auth;

  try {
    yield call(rsf.updatePassword, password);
    yield put(showAlert('Your password has been reset', { variant: 'success' }));
    yield put(updatePasswordSuccess());
  } catch ({ message }) {
    yield put(updatePasswordFailure());
    yield put(showAlert(message, { variant: 'danger' }));
  }
}
