import React from 'react';
import PropTypes from 'prop-types';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Logo from '../../assets/media/images/Logo.png';

export default class Header extends React.PureComponent {
  static propTypes = {
    logOut: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
  };

  render() {
    const {
      profile: { displayName, email },
      logOut,
    } = this.props;

    return (
      <Navbar collapseOnSelect expand="lg" className="menu-navbar">
        <Container>
          <Navbar.Brand href="#home">
            <img src={Logo} alt="logo" width="200px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-between">
            <Nav>
              <Nav.Link className="a-navbar" href="/dashboard/users">
                User
              </Nav.Link>
              <Nav.Link className="a-navbar" href="/dashboard/card-list">
                Cards
              </Nav.Link>
              <Nav.Link className="a-navbar" href="/dashboard/notifications">
                Notifications
              </Nav.Link>
              <Nav.Link className="a-navbar" href="/dashboard/new-features-request">
                Support/Feedback
              </Nav.Link>
              <Nav.Link className="a-navbar" href="/dashboard/all-rewards">
                Rewards
              </Nav.Link>
            </Nav>
            <Nav>
              <NavDropdown
                title={
                  <span>
                    <span>{displayName}</span>
                    <br />
                    <span>({email})</span>
                  </span>
                }
                id="nav-dropdown"
              >
                {/*
                <NavDropdown.Item eventKey="4.1" href="/dashboard/Settings">
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Divider />
                */}

                <NavDropdown.Item eventKey="4.2" onClick={logOut}>
                  Log out
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}
