import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from '../modules/helpers';
import { ActionTypes } from '../constants/index';

export const appState = {
  cards: [],
  count: 0,
  pages: 0,
  currentPage: 1,
  loading: false,
  loadingSave: false,
  card: null,
};

export default {
  card: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_CARDS_REQUEST]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_CARDS_SUCCESS]: (
        draft,
        {
          payload: {
            result,
            metadata: { totalItems, totalPages, currentPage },
          },
        },
      ) => {
        draft.cards = result;
        draft.count = totalItems;
        draft.pages = totalPages;
        draft.currentPage = currentPage;
        draft.loading = false;
      },
      [ActionTypes.GET_CARDS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_CARD_REQUEST]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_CARD_SUCCESS]: (draft, { payload: { result } }) => {
        draft.card = result;
        draft.loading = false;
      },
      [ActionTypes.GET_CARD_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_CARD_REQUEST]: draft => {
        draft.loadingSave = true;
      },
      [ActionTypes.CREATE_CARD_SUCCESS]: draft => {
        draft.loadingSave = false;
      },
      [ActionTypes.CREATE_CARD_FAILURE]: draft => {
        draft.loadingSave = false;
      },
      [ActionTypes.EDIT_CARDS_REQUEST]: draft => {
        draft.loadingSave = true;
      },
      [ActionTypes.EDIT_CARDS_SUCCESS]: draft => {
        draft.loadingSave = false;
      },
      [ActionTypes.EDIT_CARDS_FAILURE]: draft => {
        draft.loadingSave = false;
      },
      [ActionTypes.APPROVE_CARDS_REQUEST]: draft => {
        draft.loadingSave = true;
      },
      [ActionTypes.APPROVE_CARDS_SUCCESS]: draft => {
        draft.loadingSave = false;
      },
      [ActionTypes.APPROVE_CARDS_FAILURE]: draft => {
        draft.loadingSave = false;
      },
    },
    appState,
  ),
};
