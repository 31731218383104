import React, { Component } from 'react';
import { connect } from 'react-redux';
import AllRewardsView from './AllRewardsView';
import { getAllRewardsRequest, getAllCategoriesRequest } from '../../actions/index';

const elementsPerPage = 10;

class AllRewardsContainer extends Component {
  state = {
    currentPage: 1,
    search: '',
  };

  componentDidMount() {
    const { getRewardsFunction, getCategoriesFunction } = this.props;
    getRewardsFunction({});
    getCategoriesFunction();
  }

  changePage = currentPage => {
    this.setState({ currentPage }, () => {
      const { getRewardsFunction } = this.props;
      const { categoryFilter, search } = this.state;
      getRewardsFunction({
        currentPage,
        elementsPerPage,
        categoryFilter,
        search,
      });
    });
  };

  onFilterCategory = categoryFilter => {
    this.setState({ categoryFilter }, () => {
      const { getRewardsFunction } = this.props;
      const { search } = this.state;
      this.setState({ currentPage: 1 });

      getRewardsFunction({ currentPage: 1, elementsPerPage, categoryFilter, search });
    });
  };

  onSearch = search => {
    this.setState({ search }, () => {
      const { getRewardsFunction } = this.props;
      const { categoryFilter } = this.state;
      this.setState({ currentPage: 1 });

      getRewardsFunction({ currentPage: 1, elementsPerPage, categoryFilter, search });
    });
  };

  render() {
    const { list, loading, getRewardsFunction, pages, currentPage, categories } = this.props;
    const { categoryFilter } = this.state;
    return (
      <AllRewardsView
        list={list}
        loading={loading}
        getAllRewards={getRewardsFunction}
        changePage={this.changePage}
        currentPage={currentPage}
        pages={pages}
        categories={categories}
        onFilterCategory={this.onFilterCategory}
        onSearch={this.onSearch}
        categoryFilter={categoryFilter}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    list: state.allReward.list,
    currentPage: state.allReward.currentPage,
    pages: state.allReward.pages,
    loading: state.allReward.loading,
    categories: state.category.categories,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRewardsFunction: params => dispatch(getAllRewardsRequest(params)),
    getCategoriesFunction: () => dispatch(getAllCategoriesRequest()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllRewardsContainer);
