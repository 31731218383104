import React, { Component } from 'react';
import { Table, Form, Row, Col, Dropdown, Container, Alert } from 'react-bootstrap';
import CustomSpinner from '../../components/CustomSpinner';
import Pagination from '../../components/Pagination';
import InputSearch from '../../components/InputSearch';

export default class AllRewardsView extends Component {
  renderMaps = ({ title, categories, bankName, cardName }, key) => (
    <tr key={key}>
      <td>{title}</td>
      <td>
        {categories &&
          categories.map((type, key) =>
            key == categories.length - 1
              ? `${type.replace(/_/g, ' ')}. `
              : `${type.replace(/_/g, ' ')}, `,
          )}
      </td>
      <td> {cardName} </td>
      <td>{bankName}</td>
    </tr>
  );

  render() {
    const {
      list,
      loading,
      currentPage,
      changePage,
      pages,
      categoryFilter,
      onFilterCategory,
      categories,
      onSearch,
    } = this.props;

    return (
      <Container>
        <Row>
          <Col className="pt-5  row-spc-between" xs={12} sm={12} md={12} ls={12}>
            <h1>All Rewards</h1>
          </Col>

          <Col className="pt-5 justify-content-start" xs={12} sm={6} md={8} ls={8}>
            <Form.Group as={Row} className="mb-3" controlId="filter">
              <Col sm="12">
                <h4 className="float-left mr-3 mt-2">Filter By Category</h4>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-message-type">
                    {(categoryFilter && categoryFilter.replace(/_/g, ' ')) || 'All'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-scrollable-menu">
                    {categories.map(({ label, value }, key) => (
                      <Dropdown.Item
                        key={key}
                        active={categoryFilter === value}
                        onClick={() => {
                          onFilterCategory(value);
                        }}
                      >
                        {label.replace(/_/g, ' ')}
                      </Dropdown.Item>
                    ))}
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        onFilterCategory('');
                      }}
                    >
                      Remove filters
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Form.Group>
          </Col>

          <Col className="pt-5" xs={12} sm={6} md={4} ls={4}>
            <Form.Group as={Row} className="mb-3" controlId="search">
              <Col sm="12" className="justify-content-end">
                <InputSearch
                  onChange={search => {
                    onSearch(search);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col className="pt-5 justify-content-start" xs={12} sm={12} md={12} ls={12}>
            <Table responsive bordered hover>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Categories</th>
                  <th>Card Name</th>
                  <th>Bank Name</th>
                </tr>
              </thead>
              <tbody>
                {list && !loading && list.map((item, key) => this.renderMaps(item, key))}
              </tbody>
            </Table>
            {list && !loading && list.length === 0 && (
              <Alert variant="warning" className="input-text">
                There are currently no rewards
              </Alert>
            )}
            {loading && <CustomSpinner />}

            {!loading && (
              <Pagination currentPage={currentPage} changePage={changePage} totalPages={pages} />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
