/**
 * @module Actions/Reward
 * @desc Reward Actions
 */

import { createActions } from 'redux-actions';
import { ActionTypes } from '../constants/index';

export const { getAllRewardsRequest, getAllRewardsSuccess, getAllRewardsFailure } = createActions({
  [ActionTypes.GET_ALL_REWARDS_REQUEST]: data => data,
  [ActionTypes.GET_ALL_REWARDS_SUCCESS]: data => data,
  [ActionTypes.GET_ALL_REWARDS_FAILURE]: () => ({}),
});
