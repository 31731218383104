import app from './app';
import auth from './auth';
import card from './card';
import notification from './notification';
import newFeature from './newFeature';
import user from './user';
import allReward from './allReward';
import category from './category';

export default {
  ...app,
  ...auth,
  ...user,
  ...notification,
  ...card,
  ...newFeature,
  ...allReward,
  ...category,
};
