/**
 * @module Sagas/Notification
 * @desc Notification
 */

import { put, call } from 'redux-saga/effects';
import {
  getNotificationsRequest as getNotifications,
  getNotificationsSuccess,
  getNotificationsFailure,
  createNotificationSuccess,
  createNotificationFailure,
  editNotificationSuccess,
  editNotificationFailure,
  deleteNotificationSuccess,
  deleteNotificationFailure,
  switchFormVisible,
  showAlert,
} from 'actions/index';

export function* getNotificationsRequest(api, action) {
  const { type, params } = action.payload;

  const response = yield call(
    type === 'Sent' ? api.getSentNotifications : api.getNotifications,
    params,
  );
  const { status, data } = response;

  if (status === 200) {
    const {
      data: { result, metadata },
    } = data;
    yield put(getNotificationsSuccess({ result, metadata }));
  } else {
    const { error } = data;

    yield put(getNotificationsFailure());

    yield put(showAlert(error.message, { variant: 'danger' }));
  }
}

export function* createNotificationRequest(api, action) {
  const response = yield call(api.createNotification, action.payload);

  const { status, data } = response;
  if (status === 201) {
    yield put(switchFormVisible(false, false, null));
    yield put(createNotificationSuccess());
    yield put(showAlert('Notification created', { variant: 'success' }));
    yield put(getNotifications('Settings', {}));
  } else {
    yield put(createNotificationFailure());
    yield put(switchFormVisible(false, false, null));
  }
}

export function* editNotificationRequest(api, action) {
  const { id, item } = action.payload;
  const response = yield call(api.editNotification, id, item);

  const { status, data } = response;

  if (status === 200) {
    yield put(switchFormVisible(false, false, null));
    yield put(editNotificationSuccess());
    yield put(showAlert('Notification edited', { variant: 'success' }));
    yield put(getNotifications('Settings', {}));
  } else {
    const { error } = data;
    yield put(switchFormVisible(false, false, null));
    yield put(editNotificationFailure());
    yield put(showAlert(error.message, { variant: 'danger' }));
  }
}

export function* deleteNotificationRequest(api, action) {
  const { id } = action.payload;
  const response = yield call(api.deleteNotification, id);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Notification deleted', { variant: 'success' }));
    yield put(deleteNotificationSuccess());
    yield put(getNotifications('Settings', {}));
  } else {
    const { error } = data;
    yield put(showAlert(error.message, { variant: 'danger' }));
    yield put(deleteNotificationFailure());
  }
}
