import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

export default class ModalConfirm extends Component {
  render() {
    const { title, message, successText, successVariant, show, onCancel, onSuccess } = this.props;

    if (!show) {
      return null;
    }

    return (
      <Modal show={show} onHide={onCancel} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{message || 'Are you sure?'}</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" size="lg" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant={successVariant || 'danger'} size="lg" onClick={onSuccess}>
            {successText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
