import React, { Component } from 'react';
import Moment from 'moment';
import styled from 'styled-components';
import {
  Table,
  Button,
  Form,
  FormControl,
  Row,
  Card,
  Col,
  Dropdown,
  Container,
  Badge,
  Alert,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { replace } from '../../modules/history';
import Pagination from '../../components/Pagination';
import Icon from '../../components/Icon';
import InputSearch from '../../components/InputSearch';
import { MessageTypes } from '../../constants/index';
import { Colors } from '../../modules/theme';
import { cutString } from '../../modules/helpers';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Switch from '../../components/Switch';
import ModalConfirm from '../../components/ModalConfirm';
import CustomSpinner from '../../components/CustomSpinner';
import NotificationFormModal from './NotificationFormModal';

const elementsPerPage = 10;

const ActionButton = styled(Button)`
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 31px !important;
  color: ${props => props.color || '#4886FF'} !important;
`;

export default class NotificationView extends Component {
  state = {
    showDeleteAlert: false,
    currentId: null,
  };

  switchModalDelete = (currentId, showDeleteAlert) => {
    this.setState({ currentId, showDeleteAlert });
  };

  renderItem = (item, key) => {
    const { currentTab, switchModalForm } = this.props;

    if (currentTab === 'Sent') {
      const { id, title, body, type, userId, reciever, createdAt } = item;

      return (
        <tr key={key}>
          <td>{reciever}</td>
          <td>{cutString(title, 40)}</td>
          <td>{cutString(body, 40)}</td>
          <td>{type}</td>
          <td>{Moment(createdAt).format('MM-DD-YYYY')}</td>
          <td>{Moment(createdAt).format('hh:mm')}</td>
        </tr>
      );
    }

    if (currentTab === 'Settings') {
      const { id, title, messageType, message, active } = item;
      return (
        <tr key={key}>
          <td>{title}</td>
          <td>{messageType}</td>
          <td>{message}</td>
          <td>
            <Icon
              name={active ? 'play-circle' : 'pause-circle'}
              fontSize={22}
              color={active ? Colors.success : Colors.gray}
            />
          </td>

          <td>
            <ActionButton
              variant="link"
              color={Colors.blue2}
              onClick={() => switchModalForm(true, true, item)}
            >
              <Icon
                name="pencil-alt"
                fontSize={15}
                color={Colors.blue2}
                padding="0px 10px 0px 0px"
              />
              Edit
            </ActionButton>
            <ActionButton
              variant="link"
              color={Colors.red}
              onClick={() => this.switchModalDelete(id, true)}
            >
              <Icon name="times" fontSize={15} color={Colors.red} padding="0px 10px 0px 0px" />
              Delete
            </ActionButton>
            {/*

            <ButtonShowPage >
              
            </ButtonShowPage>
            <ButtonBlock
              
              style={{
                color: Colors.red,
              }}
            >
              
            </ButtonBlock>
            */}
          </td>
        </tr>
      );
    }
  };

  onDelete = () => {
    const { erase } = this.props;
    const { currentId } = this.state;

    erase(currentId);
    this.switchModalDelete(null, false);
  };

  onSubmit = values => {
    const { create, edit } = this.props;
    const {
      isEditing,
      item: { id },
    } = this.props;

    if (isEditing && id) {
      edit(id, values);
    } else {
      create(values);
    }
  };

  render() {
    const {
      currentTab,
      list,
      currentPage,
      pages,
      orderByDate,
      messageType,
      loading,
      loadingSave,
      erase,
      item,
      showForm,
      isEditing,
      switchModalForm,
      switchTab,
      onSearch,
      onFilter,
      onOrderByDate,
      changePage,
    } = this.props;

    const { showDeleteAlert } = this.state;

    return (
      <Container>
        <Row>
          <Col className="pt-5 row-spc-between" xs={12} sm={12} md={12} ls={12}>
            <h1 className="h1-list">Notifications</h1>
            {currentTab === 'Settings' && (
              <Button
                variant="primary"
                className="rounded-pill pl-5 pr-5"
                onClick={() => switchModalForm(true, false, null)}
              >
                Add new
              </Button>
            )}
          </Col>

          <Col className="pt-4" xs={12} sm={12} md={12} ls={12}>
            <Button
              variant="link"
              className={`btn-tab ${currentTab === 'Sent' ? 'custom-tab-active' : ''}`}
              onClick={() => {
                switchTab('Sent');
              }}
            >
              Sent
            </Button>
            <Button
              variant="link"
              className={`btn-tab ${currentTab === 'Settings' ? 'custom-tab-active' : ''}`}
              onClick={() => {
                switchTab('Settings');
              }}
            >
              Settings
            </Button>
          </Col>

          <Col className="pt-5 justify-content-start" xs={12} sm={6} md={8} ls={8}>
            <Form.Group as={Row} className="mb-3" controlId="filter">
              <Col sm="12">
                <h4 className="float-left mr-3 mt-2">Filter</h4>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-message-type">
                    {messageType || 'By Message Type'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {MessageTypes.map(({ label, value }, key) => (
                      <Dropdown.Item
                        key={key}
                        active={messageType === value}
                        onClick={() => {
                          onFilter(value);
                        }}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        onFilter(null);
                      }}
                    >
                      Remove filters
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Form.Group>
          </Col>

          <Col className="pt-5" xs={12} sm={6} md={4} ls={4}>
            <Form.Group as={Row} className="mb-3" controlId="search">
              <Col sm="12" className="justify-content-end">
                <InputSearch
                  onChange={search => {
                    onSearch(search);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col className="pt-5 justify-content-start" xs={12} sm={12} md={12} ls={12}>
            <Table responsive bordered hover>
              <thead>
                {currentTab === 'Sent' ? (
                  <tr>
                    <th>Reciever</th>
                    <th>Title</th>
                    <th>Message Sent</th>
                    <th>Message Type</th>
                    <th>
                      <span>Date Sent</span>
                      <Icon
                        name="sort"
                        fontSize={15}
                        color={Colors.white}
                        padding="0px 0px 0px 10px"
                        onClick={() => {
                          const order = orderByDate === 'desc' ? 'asc' : 'desc';
                          onOrderByDate(order);
                        }}
                      />
                    </th>
                    <th>Time Sent(EST)</th>
                  </tr>
                ) : (
                  <tr>
                    <th>Title</th>
                    <th>Message Type</th>
                    <th>Message</th>
                    <th>Active</th>
                    <th>Action</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {list && !loading && list.map((item, key) => this.renderItem(item, key))}
              </tbody>
            </Table>

            {list && !loading && list.length === 0 && (
              <Alert variant="warning" className="input-text">
                There are currently no notifications
              </Alert>
            )}

            {loading && <CustomSpinner />}

            <NotificationFormModal
              show={showForm}
              initialValues={item}
              isEditing={isEditing}
              loading={loadingSave}
              onSubmit={this.onSubmit}
              onClose={() => switchModalForm(false, false, null)}
            />
            <ModalConfirm
              title="Delete notification"
              message="Are you sure that want delete this notification?"
              successText="Delete"
              show={showDeleteAlert}
              onCancel={() => this.switchModalDelete(null, false)}
              onSuccess={this.onDelete}
            />

            {!loading && (
              <Pagination currentPage={currentPage} changePage={changePage} totalPages={pages} />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
