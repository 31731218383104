// @flow
/**
 * @module Actions/Auth
 * @desc Auth Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/index';

export const {
  login,
  loginSuccess,
  loginFailure,
  logout,
  logoutSuccess,
  logoutFailure,
  recoverPassword,
  recoverPasswordSuccess,
  recoverPasswordFailure,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailure,
  updateProfile,
  updateProfileSuccess,
  updateProfileFailure,
  updatePassword,
  updatePasswordSuccess,
  updatePasswordFailure,
} = createActions({
  // login
  [ActionTypes.LOGIN]: (email, password) => ({
    email,
    password,
  }),
  [ActionTypes.LOGIN_SUCCESS]: data => data,
  [ActionTypes.LOGIN_FAILURE]: () => ({}),
  // logout
  [ActionTypes.LOGOUT]: () => ({}),
  [ActionTypes.LOGOUT_SUCCESS]: () => ({}),
  [ActionTypes.LOGOUT_FAILURE]: () => ({}),
  // recover password
  [ActionTypes.RECOVER_PASSWORD]: email => ({ email }),
  [ActionTypes.RECOVER_PASSWORD_SUCCESS]: () => ({}),
  [ActionTypes.RECOVER_PASSWORD_FAILURE]: () => ({}),
  // reset password
  [ActionTypes.RESET_PASSWORD]: (token, password) => ({
    token,
    password,
  }),
  [ActionTypes.RESET_PASSWORD_SUCCESS]: () => ({}),
  [ActionTypes.RESET_PASSWORD_FAILURE]: () => ({}),
  // update profile
  [ActionTypes.UPDATE_PROFILE]: data => data,
  [ActionTypes.UPDATE_PROFILE_SUCCESS]: data => data,
  [ActionTypes.UPDATE_PROFILE_FAILURE]: () => ({}),
  // change password
  [ActionTypes.UPDATE_PASSWORD]: password => ({ password }),
  [ActionTypes.UPDATE_PASSWORD_SUCCESS]: () => ({}),
  [ActionTypes.UPDATE_PASSWORD_FAILURE]: () => ({}),
});
