import { hot } from 'react-hot-loader/root';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled, { css, ThemeProvider } from 'styled-components';
import history from './modules/history';
import theme from './modules/theme';
import config from './config';
import { startup, logout } from './actions';

import Login from './routes/Login';
import unauthorized from './routes/Unauthorize';
import RecoverPass from './routes/RecoverPass';
import ResetPass from './routes/ResetPass';
import Home from './routes/Home/index';
import SystemAlerts from './components/SystemAlerts';
import serverError from './routes/ServerError';
import NotFound from './routes/NotFound';
import GlobalStyles from './components/GlobalStyles';
import RoutePublic from './components/RoutePublic';
import RoutePrivate from './components/RoutePrivate';
import DashboardLayout from './components/DashboardLayout';

import 'bootstrap/dist/css/bootstrap.min.css';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;
`;

const Main = styled.main`
  min-height: 100vh;
`;

export class App extends React.Component {
  constructor(props) {
    super(props);

    const { startupApp } = this.props;
    startupApp();
  }

  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    logOut: PropTypes.func.isRequired,
    profile: PropTypes.object,
    startupApp: PropTypes.func.isRequired,
  };

  render() {
    const { isAuthenticated, profile, logOut } = this.props;

    return (
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <AppWrapper logged={isAuthenticated}>
            <Helmet
              defer={false}
              htmlAttributes={{ lang: 'pt-br' }}
              encodeSpecialCharacters={true}
              defaultTitle={config.name}
              titleTemplate={`%s | ${config.name}`}
              titleAttributes={{ itemprop: 'name', lang: 'pt-br' }}
            />
            <Main>
              <Switch>
                <RoutePublic isAuthenticated={isAuthenticated} path="/" exact component={Login} />
                <RoutePublic
                  isAuthenticated={isAuthenticated}
                  path="/home"
                  exact
                  component={Home}
                />
                <RoutePublic
                  isAuthenticated={isAuthenticated}
                  path="/login"
                  exact
                  component={Login}
                />
                <RoutePublic
                  isAuthenticated={isAuthenticated}
                  path="/recover-password"
                  exact
                  component={RecoverPass}
                />
                <RoutePublic
                  isAuthenticated={isAuthenticated}
                  path="/reset-password"
                  exact
                  component={ResetPass}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  path="/dashboard"
                  component={DashboardLayout}
                />
                <RoutePrivate
                  isAuthenticated={isAuthenticated}
                  path="/try-re-login" component={unauthorized}
                />
                <Route
                  path="/internal-server-error" component={serverError}
                />
                <Route component={NotFound} />
              </Switch>
            </Main>
            <SystemAlerts />
            <GlobalStyles />
          </AppWrapper>
        </ThemeProvider>
      </Router>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    profile: state.auth.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startupApp: () => dispatch(startup()),
    logOut: () => dispatch(logout()),
  };
}

export default hot(connect(mapStateToProps, mapDispatchToProps)(App));
