/**
 * @module Sagas/Feature
 * @desc Feature
 */

import { put, call } from 'redux-saga/effects';
import { getNewFeaturesSuccess, getNewFeaturesFailure, showAlert } from 'actions/index';

export function* getFeaturesRequest(api, action) {
  const { status, data } = yield call(api.getNewFeaturesRequest, action.payload);

  if (status === 200) {
    yield put(getNewFeaturesSuccess(data.data));
  } else {
    const { error } = data;

    yield put(showAlert(error.message, { variant: 'danger' }));
    yield put(getNewFeaturesFailure());
  }
}
