import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import Icon from '../../components/Icon';
import { Colors } from '../../modules/theme';
import { push } from '../../modules/history';
import Logo from '../../../assets/media/images/Logo.png';
import styled from 'styled-components';
const Content = styled.div``;

const InputPassword = styled.div`
  position: relative;
`;
export default class UnauthorizeView extends Component {
  render() {
    const { onLogout } = this.props;

    return (
      <Container className="h-100 justify-content-center align-items-center pt-5">
        <Row>
          <Col
            xs={12}
            sm={{ span: 8, offset: 2 }}
            md={{ span: 4, offset: 4 }}
            ls={{ span: 4, offset: 4 }}
          >
            <Content className="pb-5 text-center">
              <img src={Logo} alt="YieldBucks logo" width="260px" />
            </Content>

            <div className="pb-5 pt-5 text-center">
              <h1 className="pb-5">Your session has expired</h1>
              <Button className="mt-3" onClick={onLogout}>
                Login in again
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
