// @flow
/**
 * Client
 * @module Client
 */
import apisauce from 'apisauce';
import axios from 'axios';
import { replace } from 'modules/history';
import config from '../config';

const create = (baseURL = config.BASE_URL) => {
  const customAxiosInstance = axios.create({ baseURL });
  const api = apisauce.create({
    customAxiosInstance,
  });
  api.setBaseURL(baseURL);
  api.addResponseTransform(response => {
    if (response.status == null || response.status == 500) {
      response.data = { error: { message: 'Network error' } };
      response.status = 500;
      replace('/internal-server-error');
    } else if (response.status == 401) {
      response.data = { error: { message: 'Incorrect token is provided, try re-login' } };
      response.status = 401;
      replace('/try-re-login');
    }
  });
  // api
  const setAuthorization = token => api.setHeader('authorization', `Bearer ${token}`);
  const removeAuthToken = () => api.deleteHeader('jwt');

  // auth
  const recoverPassword = email => api.post('/auth/recover-password', { email });

  // user
  const getUsers = data => api.get('/users', data);
  const getUser = id => api.get(`/users/${id}`);
  const editUser = (id, data) => api.put(`/users/${id}`, data);
  const createUser = data => api.post('/users', data);
  const deleteUser = id => api.delete(`/users/${id}`);
  // notifications
  const getSentNotifications = params => api.get('/notifications/sent', params);
  const getNotifications = params => api.get('/notifications', params);
  const createNotification = data => api.post('/notifications', data);
  const editNotification = (id, data) => api.put(`/notifications/${id}`, data);
  const deleteNotification = id => api.delete(`/notifications/${id}`);

  // cards
  const getCards = data => api.get('/card-types', data);
  const getCard = id => api.get(`/card-types/${id}`);
  const createCard = data => api.post('/card-types', data);
  const editCards = (id, data) => api.put(`/card-types/${id}`, data);
  const approveCards = (id, data) => api.put(`/card-types/approve/${id}`, data);
  const deleteCardAndSendNotificationRequest = data => api.post('/card-types/delete', data);
  const getAllCategoriesRequest = data => api.get('/card-types/get-categories', data);
  // rewards
  const getAllRewards = data => api.get('/rewards/all-rewards', data);

  // features
  const getNewFeaturesRequest = data => api.get('/new-feature-requests', data);
  // salesman
  const getSalesman = () => api.get('/users/salesman');
  // customer
  const getCustomer = () => api.get('/users/customer');
  // job
  const getJobs = params => api.get('/jobs', params);
  const getJob = id => api.get(`/jobs/${id}`);
  const createJob = data => api.post('/jobs', data);
  const editJob = (id, data) => api.put(`/jobs/${id}`, data);
  const deleteJob = id => api.delete(`/jobs/${id}`);
  // pdf
  const createJobPdf = id => api.get(`/jobs/${id}/pdf`);
  // settings
  const getProfile = () => api.get('/my-profile');
  const getCompany = () => api.get('/my-company');

  const updateProfile = data => api.put('/my-profile', data);
  const updateCompany = data => api.put('/my-company', data);

  const changePassword = data => api.put('/users/change-password', data);
  const cronofyRegisterUser = data => api.post('/setting/cronofy-register-user', data);
  const cronofyGetCalendars = data => api.post('/setting/get-calendars', data);

  const getEvents = params => api.get('/events', params);
  const getEvent = id => api.get(`/events/${id}`);
  const createEvent = data => api.post('/events', data);
  const editEvent = (id, data) => api.put(`/events/${id}`, data);
  const deleteEvent = id => api.delete(`/events/${id}`);
  // registerCompany
  const getProducts = () => api.get('/products');
  const checkSubdomain = data => api.post('/companies/check-subdomain', data);
  // contact
  const sendContact = data => api.post('/contact', data);

  return {
    // api
    setAuthorization,
    removeAuthToken,
    // auth
    recoverPassword,
    // user
    getUsers,
    getUser,
    createUser,
    editUser,
    deleteUser,
    // notifications
    getSentNotifications,
    getNotifications,
    createNotification,
    editNotification,
    deleteNotification,
    // cards
    getCards,
    getCard,
    createCard,
    editCards,
    approveCards,
    deleteCardAndSendNotificationRequest,
    getAllCategoriesRequest,
    // rewards
    getAllRewards,
    // new features request
    getNewFeaturesRequest,
    // salesman
    getSalesman,
    // customer
    getCustomer,
    // job
    getJobs,
    getJob,
    createJob,
    editJob,
    deleteJob,
    createJobPdf,
    // events
    getEvents,
    getEvent,
    createEvent,
    editEvent,
    deleteEvent,
    // settings
    getProfile,
    getCompany,
    updateProfile,
    updateCompany,
    changePassword,
    cronofyRegisterUser,
    cronofyGetCalendars,
    // get products
    getProducts,
    checkSubdomain,
    // conatct
    sendContact,
  };
};

export default {
  create,
};
