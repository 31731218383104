import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import Icon from '../../components/Icon';
import { Colors } from '../../modules/theme';
import { push } from '../../modules/history';
import Logo from '../../../assets/media/images/Logo.png';
import styled from 'styled-components';

const Content = styled.div``;

const InputPassword = styled.div`
  position: relative;
`;

export default class LoginView extends Component {
  state = {
    hidePassword: true,
  };

  render() {
    const { submit, loading } = this.props;
    const { hidePassword } = this.state;

    return (
      <Container className="h-100 justify-content-center align-items-center pt-5">
        <Row>
          <Col
            xs={12}
            sm={{ span: 8, offset: 2 }}
            md={{ span: 4, offset: 4 }}
            ls={{ span: 4, offset: 4 }}
          >
            <Content className="pb-5 text-center">
              <img src={Logo} alt="YieldBucks logo" width="260px" />
            </Content>

            <div className="pb-5 pt-5 text-center">
              <h1>Log In</h1>
            </div>

            <Formik
              enableReinitialize
              initialValues={{ email: '', password: '' }}
              onSubmit={submit}
              validationSchema={Yup.object().shape({
                email: Yup.string().required('Required').email('Invalid Format'),
                password: Yup.string()
                  .required('Required')
                  .min(8, 'The password must have more than eight characters'),
              })}
            >
              {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-5" controlId="email">
                    <Form.Label as="h4">Email address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email && touched.email && errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-5" controlId="password">
                    <Form.Label as="h4">Password</Form.Label>
                    <InputPassword>
                      <Form.Control
                        type={hidePassword ? 'password' : 'text'}
                        name="password"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Icon
                        fontSize={22}
                        name={hidePassword ? 'eye' : 'eye-slash'}
                        color={Colors.blue}
                        customStyle={{ position: 'absolute', right: 10, top: 10 }}
                        onClick={() =>
                          this.setState({
                            hidePassword: !hidePassword,
                          })
                        }
                      />
                    </InputPassword>
                    <Form.Control.Feedback type="invalid">
                      {errors.password && touched.password && errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="button-group" className="text-center">
                    <Button
                      variant="primary mb-5 mt-5"
                      size="lg"
                      block
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? 'Loading…' : 'Login'}
                    </Button>
                    <Button
                      className="custom-btn-link"
                      variant="link"
                      onClick={() => push('/recover-password')}
                    >
                      Forgot Password?
                    </Button>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    );
  }
}
