import { hot } from 'react-hot-loader/root';
import React from 'react';
import { connect } from 'react-redux';
import { replace } from '../modules/history';
import RoutePrivate from '../components/RoutePrivate';
import User from './User';
import UserDetail from './User/UserDetail';
import Card from './Card';
import CardDetail from './Card/CardDetail';
import Notification from './Notification';
import AllRewards from './AllRewards';
import NewFeature from './NewFeature';
import Settings from './Settings';

export class DashboardRoutes extends React.Component {
  componentDidMount() {
    const {
      location: { pathname },
    } = this.props;
    if (pathname == '/dashboard' || pathname == '/dashboard/') {
      return this.getHomeComponent();
    }
  }

  getHomeComponent = () => replace('/dashboard/users');

  render() {
    const { isAuthenticated, match } = this.props;

    return (
      <>
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/users"
          component={User}
          exact
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/users/:id"
          component={UserDetail}
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/card-list"
          component={Card}
          exact
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/card-list/:id"
          component={CardDetail}
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/notifications"
          component={Notification}
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/all-rewards"
          component={AllRewards}
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/new-features-request"
          component={NewFeature}
        />
        <RoutePrivate
          isAuthenticated={isAuthenticated}
          path="/dashboard/settings"
          component={Settings}
        />
      </>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    profile: state.auth.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default hot(connect(mapStateToProps, mapDispatchToProps)(DashboardRoutes));
