import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';

export default class InputSearch extends Component {
  state = {
    delayTyping: null,
    search: '',
  };

  handleChange = (event) => {
    const search = event.target.value;
    const { onChange } = this.props;

    clearTimeout(this.state.delayTyping);
    const delayTyping = setTimeout(() => {
      onChange(search);
    }, 1000);

    this.setState({
      search,
      delayTyping,
    });
  };

  render() {
    return (
      <FormControl type="text" placeholder="Search" onChange={this.handleChange} name="search" />
    );
  }
}
