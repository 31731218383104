import React, { Component } from 'react';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const ItemComponent = styled.div`
  color: ${Colors.black2};
  padding-bottom: 5px;
  cursor: pointer;

  &: hover {
    opacity: 0.5;
  }
`;

const Higlight = styled.div`
  color: ${Colors.lightBlue};
  font-weight: 500;
`;

const Item = ({ entity }) => {
  return <ItemComponent>{entity}</ItemComponent>;
};

const Loading = () => <div>Loading</div>;

class TextareaComponent extends Component {
  getHiglight = (token) => {
    const { higlights } = this.props;

    return higlights.filter((higlight) => new RegExp(token, 'i').test(higlight));
  };

  handleChange = (event) => {
    const { onChange } = this.props;

    onChange(event.target.value);
  };

  render() {
    const { hasError, value, customStyles, disabled } = this.props;

    return (
      <ReactTextareaAutocomplete
        className="textarea-autocomplete"
        value={value}
        loadingComponent={Loading}
        onChange={this.handleChange}
        disabled={disabled}
        ref={(rta) => {
          this.rta = rta;
        }}
        innerRef={(textarea) => {
          this.textarea = textarea;
        }}
        rows={9}
        style={{
          ...customStyles,
        }}
        listStyle={{
          backgroundColor: Colors.white,
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          padding: '15px',
          listStyleType: 'none',
        }}
        minChar={0}
        trigger={{
          ':': {
            dataProvider: (token) => {
              return this.getHiglight(token);
            },
            component: Item,
            output: (item, trigger) => `<${item}>`,
          },
        }}
      />
    );
  }
}

TextareaComponent.propTypes = {
  placeholder: PropTypes.string,
};

export default TextareaComponent;
