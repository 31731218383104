import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from '../modules/helpers';
import { ActionTypes } from '../constants/index';

export const appState = {
  users: [],
  pages: 0,
  currentPage: 1,
  count: 0,
  loading: false,
  loadingSave: false,
  user: null,
};

export default {
  user: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_USERS_REQUEST]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_USERS_SUCCESS]: (
        draft,
        {
          payload: {
            result,
            metadata: { currentPage, totalItems, totalPages },
          },
        },
      ) => {
        draft.users = result;
        draft.pages = totalPages;
        draft.currentPage = currentPage;
        draft.count = totalItems;
        draft.loading = false;
      },
      [ActionTypes.GET_USERS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_USER_REQUEST]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_USER_SUCCESS]: (draft, { payload: { result } }) => {
        draft.user = result;
        draft.loading = false;
      },
      [ActionTypes.GET_USER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_USER]: draft => {
        draft.loadingSave = true;
      },
      [ActionTypes.EDIT_USER_SUCCESS]: draft => {
        draft.loadingSave = false;
      },
      [ActionTypes.EDIT_USER_FAILURE]: draft => {
        draft.loadingSave = false;
      },
    },
    appState,
  ),
};
