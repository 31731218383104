/**
 * @module Sagas/Card
 * @desc Card
 */

import { put, call } from 'redux-saga/effects';
import {
  getCardsRequest as getCards,
  getCardsSuccess,
  getCardsFailure,
  getCardSuccess,
  getCardFailure,
  createCardSuccess,
  createCardFailure,
  editCardsSuccess,
  editCardsFailure,
  approveCardsSuccess,
  approveCardsFailure,
  showAlert,
} from 'actions/index';

import { push } from '../modules/history';

export function* getCardsRequest(api, action) {
  const { status, data } = yield call(api.getCards, action.payload);
  if (status === 200) {
    yield put(getCardsSuccess(data.data));
  } else {
    const { error } = data;
    yield put(showAlert(error.message, { variant: 'danger' }));
    yield put(getCardsFailure());
  }
}

export function* getCardRequest(api, action) {
  const { status, data } = yield call(api.getCard, action.payload.id);

  if (status === 200) {
    yield put(getCardSuccess(data.data));
  } else {
    const { error } = data;
    yield put(showAlert(error.message, { variant: 'danger' }));
    yield put(getCardFailure());
  }
}

export function* createCardRequest(api, action) {
  const response = yield call(api.createCard, action.payload);

  const { status, data } = response;

  if (status === 201) {
    yield put(createCardSuccess());
    yield put(showAlert('Card created', { variant: 'success' }));
    yield put(getCards());
  } else {
    const { error } = data;
    yield put(showAlert(error.message, { variant: 'danger' }));
    yield put(createCardFailure());
  }
}

export function* editCardsRequest(api, action) {
  const { id, params } = action.payload;
  const { status: statusResponse, data } = yield call(api.editCards, id, {
    name: params.name,
    bankAddress: params.bankAddress,
    bankName: params.bankName,
    url: params.url || '',
    rewards: params.rewards,
  });

  if (statusResponse === 200) {
    yield put(editCardsSuccess());
    yield put(showAlert('Cards Edited', { variant: 'success' }));
    yield put(getCards());
    push('/dashboard/card-list');
  } else {
    const { error } = data;
    yield put(showAlert(error.message, { variant: 'danger' }));
    yield put(editCardsFailure());
  }
}

export function* approveCardsRequest(api, action) {
  const { id, params } = action.payload;
  const { status: statusResponse, data } = yield call(api.approveCards, id, params);

  if (statusResponse === 200) {
    yield put(approveCardsSuccess());
    yield put(showAlert('Cards Aproved', { variant: 'success' }));
    yield put(getCards());
    push('/dashboard/card-list');
  } else {
    const { error } = data;
    yield put(showAlert(error.message, { variant: 'danger' }));
    yield put(approveCardsFailure());
  }
}

export function* deleteCardAndSendNotificationRequest(api, action) {
  const { status: statusResponse, data } = yield call(
    api.deleteCardAndSendNotificationRequest,
    action.payload,
  );
  if (statusResponse === 201) {
    yield put(editCardsSuccess());
    yield put(showAlert('Card Deleted', { variant: 'success' }));
    yield put(getCards());
    push('/dashboard/card-list');
  } else {
    const { error } = data;
    yield put(showAlert(error.message, { variant: 'danger' }));
    yield put(editCardsFailure());
  }
}
