import { all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants/index';
import client from '../modules/client';
import { startupApp } from './startup';
import {
  login,
  logout,
  recoverPassword,
  resetPassword,
  updateProfile,
  updatePassword,
} from './auth';
import { getUsersRequest, getUserRequest, editUserRequest, deleteUserRequest } from './user';
import {
  getCardsRequest,
  getCardRequest,
  createCardRequest,
  editCardsRequest,
  approveCardsRequest,
  deleteCardAndSendNotificationRequest,
} from './card';
import {
  getNotificationsRequest,
  createNotificationRequest,
  editNotificationRequest,
  deleteNotificationRequest,
} from './notification';
import { getFeaturesRequest } from './newFeature';
import { getAllRewardsRequest } from './allReward';
import { getAllCategoriesRequest } from './category';

/**
 * rootSaga
 */
const api = client.create();

export default function* root() {
  yield all([
    // startup
    takeLatest(ActionTypes.STARTUP, startupApp, api),
    // auth
    takeLatest(ActionTypes.LOGIN, login, api),
    takeLatest(ActionTypes.LOGOUT, logout, api),
    takeLatest(ActionTypes.RECOVER_PASSWORD, recoverPassword, api),
    takeLatest(ActionTypes.RESET_PASSWORD, resetPassword),
    takeLatest(ActionTypes.UPDATE_PROFILE, updateProfile),
    takeLatest(ActionTypes.UPDATE_PASSWORD, updatePassword),
    // user
    takeLatest(ActionTypes.GET_USERS_REQUEST, getUsersRequest, api),
    takeLatest(ActionTypes.GET_USER_REQUEST, getUserRequest, api),
    takeLatest(ActionTypes.EDIT_USER_REQUEST, editUserRequest, api),
    takeLatest(ActionTypes.DELETE_USER_REQUEST, deleteUserRequest, api),
    // notification
    takeLatest(ActionTypes.GET_NOTIFICATIONS_REQUEST, getNotificationsRequest, api),
    takeLatest(ActionTypes.CREATE_NOTIFICATION_REQUEST, createNotificationRequest, api),
    takeLatest(ActionTypes.EDIT_NOTIFICATION_REQUEST, editNotificationRequest, api),
    takeLatest(ActionTypes.DELETE_NOTIFICATION_REQUEST, deleteNotificationRequest, api),
    // card
    takeLatest(ActionTypes.GET_CARDS_REQUEST, getCardsRequest, api),
    takeLatest(ActionTypes.GET_CARD_REQUEST, getCardRequest, api),
    takeLatest(ActionTypes.CREATE_CARD_REQUEST, createCardRequest, api),
    takeLatest(ActionTypes.EDIT_CARDS_REQUEST, editCardsRequest, api),
    takeLatest(
      ActionTypes.DELETE_CARD_AND_SEND_NOTIFICATION_REQUEST,
      deleteCardAndSendNotificationRequest,
      api,
    ),

    takeLatest(ActionTypes.APPROVE_CARDS_REQUEST, approveCardsRequest, api),
    // reward
    takeLatest(ActionTypes.GET_ALL_REWARDS_REQUEST, getAllRewardsRequest, api),
    // features
    takeLatest(ActionTypes.GET_NEW_FEATURES_REQUEST, getFeaturesRequest, api),
    // categories
    takeLatest(ActionTypes.GET_ALL_CATEGORIES_REQUEST, getAllCategoriesRequest, api),
  ]);
}
