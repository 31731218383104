import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import styled from 'styled-components';
import {
  Table,
  Button,
  Row,
  Col,
  Modal,
  Container,
  Card,
  ListGroup,
  ListGroupItem,
  Alert,
} from 'react-bootstrap';
import Icon from '../../components/Icon';
import { getCardRequest, editUserRequest } from '../../actions/index';
import { replace } from '../../modules/history';
import CustomSpinner from '../../components/CustomSpinner';

const ButtonShowPage = styled.button`
  background-color: transparent;
  border: none;
  aling-items: center;
  color: #33415c;
  justify-self: start;
  margin-right: 10px;
`;

const ButtonBack = styled.button`
  background-color: transparent;
  border: none;
  aling-items: center;
`;

class CardDetail extends Component {
  state = {
    showBlockAlert: false,
    showApprovedAlert: false,
    curentCardId: null,
    currentUserId: null,
  };

  componentDidMount() {
    const { getCard } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    getCard(id);
  }

  back = () => replace('/dashboard/card-list');

  block = () => {
    const { editStatus } = this.props;
    const { currentUserId: id } = this.state;
    editStatus({ id, status: 'Block' });
    this.setState({ currentUserId: null, showBlockAlert: false });
  };

  approved = id => {
    const { editStatus } = this.props;
    editStatus({ id, status: 'Active' });
    this.setState({ showApprovedAlert: false });
  };

  showModalBlock = id => {
    this.setState({ currentUserId: id, showBlockAlert: true });
  };

  renderAlert = (title, content, show, handleClose, successText, success) => (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{content}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={success}>
          {successText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
  showPageUser = id => replace(`/dashboard/users/${id}`);

  renderCard = ({ id, firstName, lastName, email, createdAt }, key) => (
    <tr key={key}>
      <td>
        <ButtonShowPage className="username-list" onClick={() => this.showPageUser(id)}>
          {`${firstName} ${lastName}`}
        </ButtonShowPage>
      </td>
      <td> {email} </td>
      <td>{Moment(createdAt).format('LLL')}</td>
    </tr>
  );

  exampleCards = [
    {
      cardid: '1',
      bank: 'bank example',
      name: 'cardexample name',
      status: 'linked',
    },
    {
      cardid: '2',
      bank: 'bank example',
      name: 'cardexample name 2',
      status: 'unlinked',
    },
  ];

  styleBack = {
    border: 'none',
  };

  render() {
    const { loading, list, card, user, editStatus } = this.props;

    return (
      <>
        {card && !loading && (
          <Container>
            <Row className="pt-4 pl-4">
              <ButtonBack onClick={this.back}>
                <Icon name="chevron-left" fontSize={25} color="black" padding="0px 0px 0px 0px" />
              </ButtonBack>
              <h1 className="h1-list">Card Info</h1>
            </Row>
            <Row>
              <Col sm={3} style={{ minHeight: '70vh', marginTop: '20px' }}>
                <Card className="user-card" border="none">
                  <Row>
                    <p className="username-card">{card.name}</p>
                    <h3 className="subtitle-card mt-5">Bank Details</h3>
                    <ListGroup variant="flush">
                      <ListGroupItem className="rewards-card">{`Name: ${card.bankName}`}</ListGroupItem>
                      <ListGroupItem className="rewards-card">{`Address:  ${card.bankAddress}`}</ListGroupItem>
                    </ListGroup>
                    <h2 className="subtitle-card">Rewards</h2>
                    <ListGroup variant="flush">
                      {(card.rewards || []).map((card, key) => (
                        <ListGroupItem key={key} className="rewards-card">
                          {card.title}
                        </ListGroupItem>
                      ))}
                      {card.rewards && card.rewards.length == 0 && (
                        <ListGroupItem className="rewards-card" variant="warning">
                          There are currently no rewards
                        </ListGroupItem>
                      )}
                    </ListGroup>
                  </Row>
                </Card>
              </Col>
              <Col sm={9} style={{ marginTop: '20px' }}>
                <Table responsive bordered hover>
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Registration Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {card.usersLinked &&
                      card.usersLinked.map((card, key) => this.renderCard(card, key))}
                  </tbody>
                </Table>

                {card.usersLinked && card.usersLinked.length === 0 && (
                  <Alert variant="warning" className="input-text">
                    There are currently no cards linked
                  </Alert>
                )}
              </Col>
            </Row>
          </Container>
        )}
        {loading && <CustomSpinner />}
        {/* Alerts */}
        {this.state.showBlockAlert &&
          this.renderAlert(
            'Block User',
            'Are you sure that want Block user?',
            this.state.showBlockAlert,
            () => this.setState({ showBlockAlert: false }),
            'Block',
            this.block,
          )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    card: state.card.card,
    loading: state.card.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCard: id => dispatch(getCardRequest(id)),
    editStatus: params => dispatch(editUserRequest(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardDetail);
