/**
 * @module Actions/User
 * @desc User Actions
 */

import { createActions } from 'redux-actions';
import { ActionTypes } from '../constants/index';

export const {
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,
  getUserRequest,
  getUserSuccess,
  getUserFailure,
  editUserRequest,
  editUserSuccess,
  editUserFailure,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFailure,
} = createActions({
  [ActionTypes.GET_USERS_REQUEST]: (data) => data,
  [ActionTypes.GET_USERS_SUCCESS]: (data) => data,
  [ActionTypes.GET_USERS_FAILURE]: () => ({}),
  [ActionTypes.GET_USER_REQUEST]: (id) => ({ id }),
  [ActionTypes.GET_USER_SUCCESS]: (data) => data,
  [ActionTypes.GET_USER_FAILURE]: () => ({}),
  [ActionTypes.EDIT_USER_REQUEST]: (data) => data,
  [ActionTypes.EDIT_USER_SUCCESS]: (data) => data,
  [ActionTypes.EDIT_USER_FAILURE]: () => ({}),
  [ActionTypes.DELETE_USER_REQUEST]: (id) => ({ id }),
  [ActionTypes.DELETE_USER_SUCCESS]: () => ({}),
  [ActionTypes.DELETE_USER_FAILURE]: () => ({}),
});
