import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login } from '../../actions/index';
import LoginView from './LoginView';

class LoginContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onLogin: PropTypes.func.isRequired,
  };

  submitLogin = (values) => {
    const { onLogin } = this.props;
    const { email, password } = values;

    onLogin(email, password);
  };

  render() {
    const { loading } = this.props;

    return <LoginView loading={loading} submit={this.submitLogin} />;
  }
}

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onLogin: (email, password) => dispatch(login(email, password)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
