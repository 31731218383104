import React, { Component } from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';
import { Table, Button, Row, Col, Modal, Container, Card, Alert } from 'react-bootstrap';
import Moment from 'moment';
import Icon from '../../components/Icon';
import { getUserRequest, editUserRequest } from '../../actions/index';
import { replace } from '../../modules/history';
import CustomSpinner from '../../components/CustomSpinner';

const ButtonShowPage = styled.button`
  background-color: transparent;
  border: none;
  aling-items: center;
  color: #33415c;
  justify-self: start;
  margin-right: 10px;
`;

const ButtonBack = styled.button`
  background-color: transparent;
  border: none;
  aling-items: center;
`;

class UserDetail extends Component {
  state = {
    showBlockAlert: false,
    showApprovedAlert: false,
    curentCardId: null,
    currentUserId: null,
  };

  componentDidMount() {
    const { getUser } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    getUser(id);
  }

  back = () => replace('/dashboard/users');

  // /////////
  block = () => {
    const { editStatus } = this.props;
    const { currentUserId: id } = this.state;
    editStatus({ id, status: 'Block' });
    this.setState({ currentUserId: null, showBlockAlert: false });
  };

  approved = id => {
    const { editStatus } = this.props;
    editStatus({ id, status: 'Active' });
    this.setState({ showApprovedAlert: false });
  };

  showModalBlock = id => {
    this.setState({ currentUserId: id, showBlockAlert: true });
  };

  renderAlert = (title, content, show, handleClose, successText, success) => (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{content}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={success}>
          {successText}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  showPageUser = cardTypeId => replace(`/dashboard/card-list/${cardTypeId}`);

  renderCard = ({ cardTypeId, official_name, bankName, linkedMethod, mask }, key) => (
    <tr key={key}>
      <td>
        <ButtonShowPage className="username-list" onClick={() => this.showPageUser(cardTypeId)}>
          {official_name}
        </ButtonShowPage>
      </td>
      <td> {bankName} </td>
      <td>{linkedMethod}</td>
      <td>{mask}</td>
    </tr>
  );

  styleBack = {
    border: 'none',
  };

  showUser(data) {
    console.log(data);
  }

  render() {
    const { loading, list, user, editStatus } = this.props;
    return (
      <>
        {user && !loading && (
          <Container>
            <Row className="p-5">
              <ButtonBack onClick={this.back}>
                <Icon name="chevron-left" fontSize={25} color="black" padding="0px 0px 0px 0px" />
              </ButtonBack>
              <h1 className="h1-list">User Info</h1>
            </Row>
            <Row>
              <Col sm={3} style={{ minHeight: '70vh', marginTop: '20px' }}>
                <Card className="user-card" border="none">
                  <Row>
                    <Col>
                      <p className="username-card">
                        {user.lastName}, {user.firstName}
                      </p>
                      <h3 className="h3-card">Date of registration</h3>
                      <p className="date-card">{Moment(user.createdAt).format('LLL')}</p>
                      <h3 className="h3-card">Email:</h3>
                      <p className="date-card">{user.email}</p>
                      <h3 className="h3-card">Phone Number</h3>
                      <p className="date-card">{user.phoneNumber}</p>
                      <h3 className="h3-card">
                        Number of Card linked: {user.cardsLinked ? user.cardsLinked.length : '-'}
                      </h3>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col sm={9} style={{ marginTop: '20px' }}>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th>Card Name</th>
                      <th>Bank Name</th>
                      <th>Source</th>
                      <th>Last 4 digits</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user.cardsLinked &&
                      user.cardsLinked.map((card, key) => this.renderCard(card, key))}
                  </tbody>
                </Table>
                {user.cardsLinked && user.cardsLinked.length === 0 && (
                  <Alert variant="warning" className="input-text">
                    There are currently no cards linked
                  </Alert>
                )}
              </Col>
            </Row>
          </Container>
        )}
        {loading && <CustomSpinner />}
        {/* Alerts */}
        {this.state.showBlockAlert &&
          this.renderAlert(
            'Block User',
            'Are you sure that want Block user?',
            this.state.showBlockAlert,
            () => this.setState({ showBlockAlert: false }),
            'Block',
            this.block,
          )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    loading: state.user.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUser: id => dispatch(getUserRequest(id)),
    editStatus: params => dispatch(editUserRequest(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
