import React, { Component, Fragment } from 'react';
import ChangeProfileForm from './ChangeProfileForm';
import ChangePasswordForm from './ChangePasswordForm';
import { updateProfile, updatePassword } from '../../actions/index';
import { connect } from 'react-redux';

class SettingsContainer extends Component {
  changeProfile = (profile) => {
    const { editProfile } = this.props;

    editProfile(profile);
  };

  changePassword = ({ password }) => {
    const { editPassword } = this.props;

    editPassword(password);
  };

  render() {
    const { profile, loading } = this.props;

    const initialValuesChangeProfile = {
      displayName: profile.displayName || '',
      email: profile.email || '',
      phoneNumber: profile.phoneNumber || '',
    };

    const initialValuesChangePassword = {
      password: '',
      confirmPassword: '',
    };

    return (
      <Fragment>
        <ChangeProfileForm
          initialValues={initialValuesChangeProfile}
          loading={loading}
          onSubmit={this.changeProfile}
        />
        <ChangePasswordForm
          initialValues={initialValuesChangePassword}
          loading={loading}
          onSubmit={this.changePassword}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.auth.profile,
    loading: state.auth.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editProfile: (profile) => dispatch(updateProfile(profile)),
    editPassword: (password) => dispatch(updatePassword(password)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
