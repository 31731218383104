import React, { Component } from 'react';
import Moment from 'moment';
import styled from 'styled-components';
import { Table, Button, Form, Row, Col, Container, Alert } from 'react-bootstrap';
import Pagination from '../../components/Pagination';
import Icon from '../../components/Icon';
import InputSearch from '../../components/InputSearch';
import { Colors } from '../../modules/theme';
import { cutString } from '../../modules/helpers';

import CustomSpinner from '../../components/CustomSpinner';
import ShowDetailsModal from './ShowDetailsModal';

const elementsPerPage = 10;

const ActionButton = styled(Button)`
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 31px !important;
  color: ${props => props.color || '#4886FF'} !important;
`;

export default class NotificationView extends Component {
  state = {
    showDetails: false,
    itemSelected: null,
  };

  switchModalDetails = (showDetails, itemSelected) => {
    this.setState({ showDetails, itemSelected });
  };

  renderItem = (item, key) => {
    const { name, email, message, requestType, createdAt } = item;

    return (
      <tr key={key}>
        <td>{name}</td>
        <td>{requestType}</td>
        <td>{email}</td>
        <td>{cutString(message, 40)}</td>
        <td>{Moment(createdAt).format('LLL')}</td>

        <td>
          <ActionButton
            variant="link"
            color={Colors.blue2}
            onClick={() => this.switchModalDetails(true, item)}
          >
            <Icon name="eye" fontSize={15} color={Colors.blue2} padding="0px 10px 0px 0px" />
            Show
          </ActionButton>
        </td>
      </tr>
    );
  };

  render() {
    const {
      list,
      currentPage,
      pages,
      orderByDate,
      loading,
      onSearch,
      onOrderByDate,
      changePage,
    } = this.props;

    const { showDetails, itemSelected } = this.state;

    return (
      <Container>
        <Row>
          <Col className="pt-5 row-spc-between" xs={12} sm={12} md={12} ls={12}>
            <h1 className="h1-list">Support/Feedback request</h1>
          </Col>

          <Col className="pt-5" xs={12} sm={6} md={4} ls={4}>
            <Form.Group as={Row} className="mb-3" controlId="search">
              <Col sm="12" className="justify-content-end">
                <InputSearch
                  onChange={search => {
                    onSearch(search);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col className="pt-5 justify-content-start" xs={12} sm={12} md={12} ls={12}>
            <Table responsive bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Email</th>
                  <th>Message</th>
                  <th>
                    <span>Date</span>
                    <Icon
                      name="sort"
                      fontSize={15}
                      color={Colors.white}
                      padding="0px 0px 0px 10px"
                      onClick={() => {
                        const order = orderByDate === 'desc' ? 'asc' : 'desc';
                        onOrderByDate(order);
                      }}
                    />
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {list && !loading && list.map((item, key) => this.renderItem(item, key))}
              </tbody>
            </Table>

            {list && !loading && list.length === 0 && (
              <Alert variant="warning" className="input-text">
                There are currently no features request
              </Alert>
            )}

            {loading && <CustomSpinner />}

            <ShowDetailsModal
              show={showDetails}
              values={itemSelected}
              onClose={() => this.switchModalDetails(false, null)}
            />

            {!loading && (
              <Pagination currentPage={currentPage} changePage={changePage} totalPages={pages} />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
